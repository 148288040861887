import React, { useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'; // Import the arrow icons


const AccordionItem = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div className='d-flex flex-column'>
                <div className='dropdownkids' onClick={toggleAccordion}>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                        <span>{title}</span>
                        <span>
                            {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </span>
                    </div>
                </div>
                {isOpen && <div className='whitecontent'>{content}</div>}
            </div>
        </div>
    )
}

export default AccordionItem
