import React, { useState,useEffect } from 'react'
import "./Inventor.css"
import Inventors from '../../Assests/Inventor.png'
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Inventor = ({RoutesClick, NcertClick}) => {
    const [registerModal, setRegisterModal] = useState(false);
    const [activeTabHeader, setActiveTabHeader] = useState("Inventor");
    const [studentregisterModal, setStudentRegisterModal] = useState(false);
    const [ParentregisterModal, setParentRegisterModal] = useState(false);
    const [TeacherregisterModal, setTeacherregisterModal] = useState(false);

    const handleTabClickHeader = (tab) => {
        setActiveTabHeader(tab);
      };

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Header
                activeTab={activeTabHeader}
                handleTabClick={handleTabClickHeader}
                studentregisterModal={studentregisterModal}
                setStudentRegisterModal={setStudentRegisterModal}
                ParentregisterModal={ParentregisterModal}
                setParentRegisterModal={setParentRegisterModal}
                TeacherregisterModal={TeacherregisterModal}
                setTeacherregisterModal={setTeacherregisterModal}
                registerModal={registerModal}
                setRegisterModal={setRegisterModal}
                RoutesClick={RoutesClick}
                NcertClick={NcertClick}
            />
                <div>
                    <div className='imgBack'>
                        <div className='container py-5'>
                            <div className="row align-items-start h-100 d-flex justify-content-center align-items-center pt-5">
                                <div className="col-md-4">
                                    <div className='d-flex flex-column justify-content-between align-items-start mb-3'>
                                        <img src={Inventors} className='imgs' />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className='d-flex flex-column justify-content-start align-items-start gap-4 mt-5'>
                                        <div className='inventorHead'>
                                            Inventor - Sarath Kakumanu
                                        </div>
                                        <div className='inventorContent'>
                                            Mr. Sarath Kakumanu pursued his Bachelor’s Degree in Architecture from the School of Architecture and Planning, at the University of Madras, now known as Anna University.
                                        </div>
                                        <div className='inventorContent'>
                                            He earned his Master’s Degree in Architecture from Kent State University of Ohio in 1976-78 and secured an M.S. in Urban Planning at Akron University, Ohio, in 1979.
                                        </div>
                                        <div className='inventorContent'>
                                            He is an entrepreneur with innovative ideas, with over 5 decades of rich experience in Design and Construction Management.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='container py-5'>
                        <div className='d-flex flex-column gap-3'>
                            <div className='journeyHead'>
                                Software journey
                            </div>

                            <div className='journey_Content'>
                                He is very passionate about providing digital solutions to many existing issues and has rich experience of <br /> 3 decades in software development.
                            </div>
                            <div className='journey_Content'>
                                Software development started in 1992 with KAMS, the end-to-end Construction Activity Management Solution.
                            </div>
                        </div>
                    </div>


                    <div className='container py-3'>
                        <div className='d-flex justify-content-center align-items-center w-100'>
                            <div className='invertorBlue px-5'>
                                <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                                    <div className='bluecontent pt-5'>
                                        He has architected and developed the following flagship products
                                    </div>
                                    <div className='blueHead'>
                                        KAMS
                                    </div>
                                    <div className='bluecontent'>
                                        End-to-end Construction Activity Management Solution
                                    </div>
                                    <div className='blueHead'>
                                        KPOST
                                    </div>
                                    <div className='bluecontent'>
                                        Innovative, Integrated, Advanced OPEN Communication Solution.
                                    </div>
                                    <div className='blueHead'>
                                        KSMACC
                                    </div>
                                    <div className='bluecontent pb-5'>
                                        Complete CLOSED (intra-organizational) Communication Solution.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='container py-3'>
                        <div className='d-flex justify-content-center align-items-center w-100'>
                            <div className='invertorBlue px-5'>
                                <div className='bluecontentSec py-5'>
                                    Project Management Solutions Suite for different verticals like
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                    <div className='blueHeadSec'>
                                        Project Management
                                    </div>
                                    <div className='blueHeadSec'>
                                        Digital Drawing Management
                                    </div>
                                    <div className='blueHeadSec'>
                                        Software Activity Management
                                    </div>
                                    <div className='blueHeadSec'>
                                        Real Estate Marketing and Sales
                                    </div>
                                    <div className='blueHeadSec pb-5'>
                                        Facility Management
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='container py-5'>
                        <div className='d-flex justify-content-center align-items-center w-100'>
                            <div className='d-flex flex-column justify-content-center align-items-center gap-4'>
                                <div className='zildingContent'>
                                    Zilding Database for all building-related materials, construction materials, construction Machinery and Tools, <br /> Electrical Materials, Building Equipment Auxiliaries, Appliances, and Machines both general and specific based on <br /> the country of origin.
                                </div>
                                <div className='zildingContent'>
                                    He has invented a revolutionary meta document format called ‘KADD’ – Collaborative Advanced Digital Document <br /> which is far superior to the existing document formats with its inbuilt collaboration system.
                                </div>
                                <div className='zildingContent'>
                                    He also invented KATBOOK, the revolutionary Collaborative Advanced Digital Textbook as part of ‘UEDUCATE’ the <br /> end-to-end digital solution for education.
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='container py-3'>
                        <div className='d-flex justify-content-center align-items-center w-100'>
                            <div className='invertorBlue px-5'>
                                <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                                    <div className='bluecontentUeducate pt-5'>
                                        ‘UEDUCATE’ consists of
                                    </div>
                                    <div className='blueHead'>
                                        KAMPUS
                                    </div>
                                    <div className='bluecontent'>
                                        The Educational Institution Management Solution
                                    </div>
                                    <div className='blueHead'>
                                        KATBOOK
                                    </div>
                                    <div className='bluecontent'>
                                        The Collaborative Advanced Digital Textbook
                                    </div>
                                    <div className='blueHead'>
                                        KSMACC
                                    </div>
                                    <div className='bluecontent'>
                                        The Intra institutional communication
                                    </div>
                                    <div className='blueHead'>
                                        USTUDY
                                    </div>
                                    <div className='bluecontent'>
                                        The Global Academic Aggregator for Higher Education and Skill Development
                                    </div>
                                    <div className='blueHead'>
                                        KATBOOK STORE
                                    </div>
                                    <div className='bluecontent pb-5'>
                                        the e-commerce site for all KATBOOKS published globally
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <div className='endofhteline'></div>
                    </div>

                </div>
            <Footer 
            setActiveTab={setActiveTabHeader}
            RoutesClick={RoutesClick}
            NcertClick={NcertClick}
             />       
        </div>
    )
}

export default Inventor
