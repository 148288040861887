// export const SessionStoreValue = () => {
//     let data = JSON.parse(localStorage.getItem("userData") || "{}");
//     return Object.values(data).length > 0 ? true : false;
//   };
// export const SessionStoreValue = () => {
//     const userData = JSON.parse(sessionStorage.getItem("user") || "{}");
//     const isAuthenticated = Object.keys(userData).length > 0;
//     console.log(isAuthenticated, userData, "isAuthenticated");
//     return isAuthenticated;
// };
export const SessionStoreValue = () => {
    const token = localStorage.getItem('token');
    return !!token; 
  };
// export const SessionStoreValue = () => {
//     let data = JSON.parse(sessionStorage.getItem("user") || "{}");
//     return Object.values(data).length > 0 ? true : false;
//   };
  