import React, { useState, useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Property from "../../Assests/Component 150.png"
import Property2 from "../../Assests/Frame 1000006056.png"
import Property3 from "../../Assests/modimbi.png"
import Property4 from "../../Assests/Slide 2.png"
import MediaQuery from 'react-responsive';
import laptop from "../../Assests/Slide 1 Katbook 1.png"
import logoUedu from "../../Assests/KatbookNew.png"
import appstore from "../../Assests/Group.png"
import playstore from "../../Assests/Group (1).png"
import mobile from "../../Assests/smartphones 1.png"
import grennLogo from "../../Assests/R2R_logo_TM.png"
import { Link } from 'react-router-dom'
import evidhya from '../../Assests/e-vidya-logo-new-removebg-preview 1.png'
import ncret from '../../Assests/NCERT_300px.png'
import R2R from '../../Assests/R2R_logo_TM.png'
import DiKSHA from '../../Assests/diksha_logo.png'
import moodi from '../../Assests/Modi 1.png'
import Inventor from '../../Assests/Inventor.png'
import uEdu from '../../Assests/KatbookNew.png'
import "./Home.css"
import Header from '../header/Header'
import Footer from '../footer/Footer'



const Home = ({ RoutesClick, NcertClick, Rootsref, Ncertref }) => {

    const [registerModal, setRegisterModal] = useState(false);
    const [activeTabHeader, setActiveTabHeader] = useState("Home");
    const [studentregisterModal, setStudentRegisterModal] = useState(false);
    const [ParentregisterModal, setParentRegisterModal] = useState(false);
    const [TeacherregisterModal, setTeacherregisterModal] = useState(false);

    const handleTabClickHeader = (tab) => {
        setActiveTabHeader(tab);
    };

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Header
                activeTab={activeTabHeader}
                handleTabClick={handleTabClickHeader}
                studentregisterModal={studentregisterModal}
                setStudentRegisterModal={setStudentRegisterModal}
                ParentregisterModal={ParentregisterModal}
                setParentRegisterModal={setParentRegisterModal}
                TeacherregisterModal={TeacherregisterModal}
                setTeacherregisterModal={setTeacherregisterModal}
                registerModal={registerModal}
                setRegisterModal={setRegisterModal}
                RoutesClick={RoutesClick}
                NcertClick={NcertClick}
            />
            <div className='py-5'>
                <div>
                    <MediaQuery minWidth={700}>
                        <Carousel data-bs-theme="dark" >
                            <Carousel.Item>
                                <div className='imgBack'>
                                    <div className='container py-5 d-flex align-items-center'>
                                        <div className="col-md-12 d-flex justify-content-center align-items-center h-100">
                                            

                                            <div className="col-md-5">
                                                <div className='d-flex flex-column gap-3'>
                                                    <div>
                                                        <img src={moodi} className='width_mob' />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-7">
                                                <div className='d-flex flex-column justify-content-between align-items-start gap-3'>
                                                    <div className='tabletHeading webDis'>
                                                        KATBOOK
                                                    </div>
                                                    <div className='mb-3 d-flex flex-column gap-2'>
                                                        <div className='carsoulfirstContent'>The Collaborative Advanced Digital Textbook</div>
                                                    </div>
                                                    <div className='d-flex align-items-center gap-4'>
                                                        <img src={evidhya} width={"12%"} />
                                                        <img src={ncret} width={"8%"} />
                                                        <img src={R2R} width={"20%"} />
                                                        <img src={DiKSHA} />
                                                        <img src={uEdu} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className='imgBack'>
                                    <div className='container py-5 d-flex align-items-center' style={{ height: '645px' }}>
                                        <div className="col-md-12 d-flex justify-content-center align-items-center h-100" >
                                            <div className="col-md-6">
                                                <div className='d-flex flex-column gap-3'>
                                                    <div>
                                                        <img src={laptop} className='width_mob' />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className='d-flex flex-column justify-content-between align-items-start'>
                                                    <div className='tabletHeading webDis'>
                                                        KATBOOK Top Features
                                                    </div>
                                                    <div className='mb-3 d-flex flex-column gap-2'>
                                                        <ul>
                                                            <li className='about_COntent_Li'>Additional content on Keywords, Concept Videos, Teaching Videos</li>
                                                            <li className='about_COntent_Li'>Al-assisted Question and answer Generations</li>
                                                            <li className='about_COntent_Li'>Study Material, Self-evaluations, previous year question papers</li>
                                                            <li className='about_COntent_Li'>Notes, Highlight, Bookmark, Translate, Hyperlink, Dictionary, Audio reader and many more.</li>
                                                        </ul>
                                                    </div>
                                                    <Link to={"/katbook"} className='aboutBtn px-3 py-2 textDecvor' onClick={() => setActiveTabHeader('KATBOOK')}>
                                                        Read more
                                                    </Link >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </MediaQuery>
                    <MediaQuery maxWidth={700}>
                        <Carousel data-bs-theme="dark">
                            <Carousel.Item>
                                <img
                                    className="d-block w-100 imgBack"
                                    src={Property3}
                                    alt="First slide"
                                    height={429}
                                />
                            </Carousel.Item>
                            <Carousel.Item>

                                <img
                                    className="d-block w-100 imgBack"
                                    src={Property4}
                                    alt="First slide"
                                    height={429}
                                />
                            </Carousel.Item>
                        </Carousel>

                    </MediaQuery>
                </div>

                <div>

                    <div className='container w-100'>
                        <div className='d-flex justify-content-center align-items-center my-4'>
                            <span className='fontHead-e'>PM e-Vidya KATBOOK</span>
                        </div>

                        <div className='d-flex justify-content-center align-items-center secon_contentFont'>
                            KATBOOK from PM E-Vidya initiative will benefit 25 crores of children with its innovative features like Additional content on all
                        </div>
                        <div className='d-flex justify-content-center align-items-center secon_contentFont mb-3'>
                            keywords, Concept videos, Teaching videos, and many other patented first-of-its-kind features.
                        </div>
                    </div>


                    <div className='backAbout'>
                        <div className='container py-5'>
                            <div className="row align-items-start h-100 d-flex justify-content-center align-items-center">
                                <div className="col-md-6">
                                    <div className='tabletHeading mobiDis'>
                                        ABOUT KATBOOK
                                    </div>
                                    <div className='d-flex flex-column gap-3'>
                                        <div className='text_center_mobile'>
                                            <img src={laptop} className='width_mob' />
                                        </div>
                                        <div>
                                            <img src={logoUedu} className='webDis' />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='d-flex flex-column justify-content-between align-items-start'>
                                        <div className='tabletHeading webDis'>
                                            ABOUT KATBOOK
                                        </div>
                                        <div className='d-flex flex-column justify-content-start align-items-start gap-3 mb-3'>
                                            <div className='about_COntent'>KATBOOK is an innovative digital textbook, a first-of-its-kind with many innovative, patented features to offer to its users.</div>
                                            <div className='about_COntent'>KATBOOK is a part of UEDUCATE, the end-to-end digital solution for education, developed by UEducate Global Private Limited, Chennai. The Vision of the company is 'To provide every student access to quality education, by which everyone will become compassionate, kind, hard-working and giving to all'.</div>
                                            <div className='about_COntent'>KATBOOK has enhanced content in the form of Additional content for all keywords, Concept videos, Teaching videos, and Study material. Self-evaluation for students is also built into the digital KATBOOK.</div>
                                        </div>
                                        <div className='button_center_Mobile'>
                                            <Link to={"/katbook"} className='aboutBtn px-3 py-2 textDecvor' onClick={() => setActiveTabHeader('KATBOOK')}>
                                                Read more
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='container py-3 '>
                        <div className="row align-items-start h-100 d-flex justify-content-center align-items-center">
                            <div className="col-md-6">
                                <div className='d-flex flex-column justify-content-start align-items-start gap-4 mt-5'>
                                    <div className='tabletHeading'>
                                        KATBOOK Mobile App
                                    </div>
                                    <div className='about_COntent'>KATBOOK can be accessed on desktops/laptops/tablet computers and mobile phones of Android and iOS operating systems. The mobile application gives the convenience of studying from anywhere at any time.</div>
                                    <MediaQuery minWidth={700}>
                                        <div className='d-flex justify-content-start gap-3'>
                                            <a href="https://apps.apple.com/us/app/pm-evidya-katbook/id6474897126" target="_blank">
                                                <div>
                                                    <img src={appstore} />
                                                </div>
                                            </a>
                                            <a href="https://play.google.com/store/apps/details?id=com.ueducate.pmevidya&pcampaignid=web_share" target="_blank">
                                                <div>
                                                    <img src={playstore} />
                                                </div>
                                            </a>
                                        </div>
                                    </MediaQuery>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='d-flex flex-column justify-content-between align-items-start'>
                                    <img src={mobile} className='imgs' />
                                </div>
                                <MediaQuery maxWidth={700}>
                                    <div className='d-flex justify-content-center gap-3 mt-3 mb-3'>
                                        <a href="https://apps.apple.com/us/app/pm-evidya-katbook/id6474897126" target="_blank">
                                            <div>
                                                <img src={appstore} />
                                            </div>
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.ueducate.pmevidya&pcampaignid=web_share" target="_blank">
                                            <div>
                                                <img src={playstore} />
                                            </div>
                                        </a>
                                    </div>
                                </MediaQuery>
                            </div>
                        </div>
                    </div>



                    <div className='InventorBack'>
                        <div className='container py-3'>
                            <div className="row align-items-start h-100 d-flex justify-content-center align-items-center">
                                <div className="col-md-4">
                                    <div className='d-flex flex-column justify-content-between align-items-start mb-3'>
                                        <img src={Inventor} className='imgs' />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className='d-flex flex-column justify-content-start align-items-start gap-4 mt-5'>
                                        <div className='inventorHead'>
                                            Inventor - Sarath Kakumanu
                                        </div>
                                        <div className='inventorContent'>
                                            Mr. Sarath Kakumanu pursued his Bachelor’s Degree in Architecture from the School of Architecture and Planning, at the University of Madras, now known as Anna University.
                                        </div>
                                        <div className='inventorContent'>
                                            He earned his Master’s Degree in Architecture from Kent State University of Ohio in 1976-78 and secured an M.S. in Urban Planning at Akron University, Ohio, in 1979.
                                        </div>
                                        <div className='inventorContent'>
                                            He is an entrepreneur with innovative ideas, with over 5 decades of rich experience in Design and Construction Management.
                                        </div>

                                        <div className='button_center_Mobile'>
                                            <Link to="/inventor">
                                                <button className='px-3 py-2 textDecvor moreBtn' onClick={() => setActiveTabHeader('Inventor')}>Read More</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className='container d-flex flex-column justify-content-center gap-3 py-5' ref={Ncertref}>
                        <div className='d-flex justify-content-center inventorHead'>
                            NCERT
                        </div>
                        <div className='about_NCERT'>
                            The National Council of Educational Research and Training (NCERT) is an autonomous organisation set up in 1961
                            by the Government of India to assist and advise the Central and State Governments on policies and programmes
                            for qualitative improvement in school education. The major objectives of NCERT and its constituent units are
                            to: undertake, promote and coordinate research in areas related to school education; prepare and publish
                            model textbooks, supplementary material, newsletters, journals and develops educational kits, multimedia
                            digital materials, etc.
                        </div>
                        <div className='button_center_Mobile d-flex justify-content-center'>
                            <button className='px-3 py-2 textDecvor moreBtn'>
                                <a onClick={() => window.open(`https://ncert.nic.in/`, "_blank")} className='ncert_tab'>Read More</a>
                            </button>
                        </div>
                    </div>



                    <div className='greenBack' ref={Rootsref} >
                        <div className='container'>
                            <div className='d-flex flex-column justify-content-center align-items-center gap-3 py-5'>
                                <div className='d-flex justify-content-center'>
                                    <img src={grennLogo} width={"40%"} />
                                </div>
                                {/* <div className='tabletHeading'>
                                    Routes 2 Roots
                                </div> */}
                                <div className='rrFont pt-3'>
                                    Routes 2 Roots is a nonprofit organization and one of the best NGOs in Delhi, striving towards helping children <br />
                                    grasp Indian culture and Performing arts through the medium of the “Digital Learning of Performing Arts” <br />
                                    program and physical workshops in the schools of KV, JNV, Govt, and Army Public Schools.
                                </div>

                                <div className='rrhead'>KATBOOK is brought to the students of India as part of PM e-Vidya through Routes 2 Roots.</div>

                                {/* <div className='aboutBtn px-3 py-2 '>
                                    Read More
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer 
            setActiveTab={setActiveTabHeader}
            RoutesClick={RoutesClick}
            NcertClick={NcertClick}
             />

        </div>
    )
}

export default Home
