import React, { useState, useEffect } from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import katbooklogo from '../../Assests/foot.png'
import { FaChevronDown } from "react-icons/fa";
import profile from '../../Assests/Profile_Logo.png'
import chemistry from '../../Assests/Chemistry 1.png'
import Botany from '../../Assests/Bio-Botany 1.png'
import { IoPersonCircle } from "react-icons/io5";
import { MdOutlineLogout } from "react-icons/md";
import { RiPushpinFill } from "react-icons/ri";
import './Bookfetch.css'
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getAllKatBookSubjects } from '../../Services/ApiService';
import CryptoJS from 'crypto-js';
import Header from '../header/Header'

const Bookfetch = ({ activeTab, handleTabClick, studentregisterModal, setStudentRegisterModal, ParentregisterModal, setParentRegisterModal, TeacherregisterModal, setTeacherregisterModal }) => {

    const secretKey = 'KampusNcrt';
    const key = "KPOSTKatBooks#$*(@#)!!";
    const UserProfileData = JSON.parse(localStorage.getItem('userData'));
    console.log(UserProfileData);
    const encryptedSubjects = localStorage.getItem('encryptedSubjects');
    const decryptedSubjects = CryptoJS.AES.decrypt(encryptedSubjects, secretKey);
    const subjects = JSON.parse(decryptedSubjects.toString(CryptoJS.enc.Utf8));
    const usercode = localStorage.getItem('userCode')

    const decrypteduserCode = CryptoJS.AES.decrypt(usercode, secretKey);
    const userrescode = JSON.parse(decrypteduserCode.toString(CryptoJS.enc.Utf8));

    const decryptedRegUser = CryptoJS.AES.decrypt(userrescode, "!@#$%^");
    const RegUserRes = decryptedRegUser.toString(CryptoJS.enc.Utf8);

    const userid = localStorage.getItem('userID')
    const decrypteduserIDD = CryptoJS.AES.decrypt(userid, secretKey);
    const userresidd = JSON.parse(decrypteduserIDD.toString(CryptoJS.enc.Utf8));

    const navigate = useNavigate();
    const [dropdown, setDropdown] = useState(false)
    const handleclick = () => {
      setDropdown(!dropdown)
    }

  const [userData, setUserData] = useState([]);
  const [userName, setUserName] = useState([]);
  const [subjectArr, setsubjectArr] = useState([]);
  const [userCode, setuserCode] = useState("");
  const [userId, setuserID] = useState("");
  const [Bookencrypteddata, setBookencrypteddata] = useState("");
  const [standardArr, setstandardArr] = useState([]);
  const [addbookOpen, setaddbookOpen] = useState(false)


  
  
  useEffect(() => {

    const username = localStorage.getItem('username')
    
  setUserName(username);
  const encryptedStandard = localStorage.getItem('encryptedStandard');
    
    if(encryptedStandard !== null && encryptedStandard !== undefined && encryptedStandard !== ""){

    console.log(subjects);
    const decryptedStandard = CryptoJS.AES.decrypt(encryptedStandard, secretKey);
    const standard = JSON.parse(decryptedStandard.toString(CryptoJS.enc.Utf8));
    setstandardArr(standard)
    console.log(standard,"standardstandard");
    // const storedUserData = localStorage.getItem('userData');
    // if (storedUserData) {
    //   const parsedUserData = JSON.parse(storedUserData);
    //   setUserData(parsedUserData);
    // }
    getSubjectDetails();

    } else {
      localStorage.removeItem('userID');
      localStorage.removeItem('userCode');
      localStorage.removeItem('username');
      localStorage.removeItem('encryptedMedium');
      localStorage.removeItem('encryptedSubjects');
      localStorage.removeItem('encryptedStandard');
      localStorage.removeItem('token');
      localStorage.removeItem('userData');
      localStorage.removeItem('IsUserAuth');
      localStorage.clear();

      navigate("/");

    }
  }, []);

  const handleLogout = () => {

    localStorage.removeItem('userID');
    localStorage.removeItem('userCode');
    localStorage.removeItem('username');
    localStorage.removeItem('encryptedMedium');
    localStorage.removeItem('encryptedSubjects');
    localStorage.removeItem('encryptedStandard');
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    localStorage.removeItem('IsUserAuth');
    localStorage.clear();

    navigate("/");
  };


  const getSubjectDetails = async (BookIDs) => {
    // console.log(BookIDs,"BookIDsBookIDs");
    // console.log(subjects.map(subject => subject._id),"BookIDsBookIDs");
    try {
      let bod = {
        bookIds: BookIDs ? BookIDs : subjects.map(subject => subject._id),

      };

      let res = await getAllKatBookSubjects(bod);
      if (res?.success === true) {
        setsubjectArr(res.units)
      }
      else {
        console.log(res.error, res, "testtstst")

      }
    } catch (error) {
      console.log(error)
    }

  };

  const handleBookClick = (subjectId) => {
    console.log(subjectId,"subjectId")
    const encryptedStandard = localStorage.getItem('encryptedStandard');
    const decryptedStandard = CryptoJS.AES.decrypt(encryptedStandard, secretKey);
    const standard = JSON.parse(decryptedStandard.toString(CryptoJS.enc.Utf8));

    const encryptedMedium = localStorage.getItem('encryptedMedium');
    const decryptedMedium = CryptoJS.AES.decrypt(encryptedMedium, secretKey);
    const medium = JSON.parse(decryptedMedium.toString(CryptoJS.enc.Utf8));

   
    const usercode = localStorage.getItem('userCode')

    const decrypteduserCode = CryptoJS.AES.decrypt(usercode, secretKey);
    const userrescode = JSON.parse(decrypteduserCode.toString(CryptoJS.enc.Utf8));

    const decryptedRegUser = CryptoJS.AES.decrypt(userrescode, "!@#$%^");
    const RegUserRes = decryptedRegUser.toString(CryptoJS.enc.Utf8);

    const userid = localStorage.getItem('userID')
    const decrypteduserIDD = CryptoJS.AES.decrypt(userid, secretKey);
    const userresidd = JSON.parse(decrypteduserIDD.toString(CryptoJS.enc.Utf8));

    const stdid=standard.map(standard => standard._id);
    const mediumid=medium._id;

  };
  return (
    <div className='spacing' >
        <Header
            studentregisterModal={studentregisterModal}
            setStudentRegisterModal={setStudentRegisterModal}
            ParentregisterModal={ParentregisterModal}
            setParentRegisterModal={setParentRegisterModal}
            TeacherregisterModal={TeacherregisterModal}
            setTeacherregisterModal={setTeacherregisterModal}
            userAuth={localStorage.getItem('IsUserAuth')}
            setaddbookOpen={setaddbookOpen}
            addbookOpen={addbookOpen}
            RefreshSubjects={(bookArray)=>getSubjectDetails(bookArray)}
            />
      <div className='d-flex justify-content-between align-item-center px-4 py-2 '>

        <div style={{ width: "8%"}}><img src={katbooklogo} alt='PM e-Vidth KATBOOK' style={{width: "100%"}} /></div>

        <div className='d-flex align-items-center gap-5'>
         
          <div className='d-flex align-item-center gap-2'>
            <div><img src={profile} alt='profile' /></div>
            <div className='d-flex gap-2 align-items-center' onClick={handleclick} style={{ cursor: "pointer" }}>
              <div className='d-flex flex-column' >
                <div className='text'>{userName}</div>
                <div className='section'>{UserProfileData?.userTypeId?.usertype === "Teacher" ? "TEACHER" : UserProfileData?.userTypeId?.usertype === "Parent" ? "PARENT" : `${standardArr[0]?.variableName}`} </div>
              </div>
              <div className='pt-0'  ><FaChevronDown size={12} /></div>
            </div>
          </div>
        </div>

      </div>

      {dropdown && (
        <div className='d-flex flex-column gap-2 backgroundeffect '>
          <div className='account'> Account</div>
          <div className='sections  px-3 py-1'><IoPersonCircle size={20} />&nbsp;{userName}</div>
          {/* <div className='key px-3 py-1'><RiPushpinFill size={20} />&nbsp; @122334</div> */}
          <div className='sections px-3 py-1' onClick={handleLogout}><MdOutlineLogout size={20} />&nbsp; Log Out</div>
        </div>
      )}

      <div className='px-4 '>
        <div className=' border '>
          <div className='background'>
            <div className={"d-flex justify-content-between"}>
            <div className='d-flex align-items-center katbooktext px-3 p-2'>{UserProfileData?.userTypeId?.usertype === "Parent" ? `${UserProfileData?.studentId?.firstName} ${UserProfileData?.studentId?.lastName}'s KATBOOKs` : `${UserProfileData?.firstName}'s KATBOOKs`}</div>
            {UserProfileData?.userTypeId?.usertype !== "Parent" && <div className="addBookBtn cursor" onClick={() => { 
              setaddbookOpen(true)
              }}
            > Add Katbook</div>}
            </div>
            <div className='d-flex flex-wrap gap-4 px-5 py-3 '>

              {subjectArr.map((x) => {
                const BooksText = `${x.path.split('"parentVaribaleId"')[0]}"parentVaribaleId":{"username":"${userresidd}","code":"${RegUserRes}|${UserProfileData?.userTypeId?.usertype}"}}/${x.standard}/${x.bookId}/789/1`;

                const Bookencrypted = CryptoJS.AES.encrypt(
                  JSON.stringify(BooksText),
                  key
                ).toString();

                const StdID = subjects.filter(q => q._id === x._id).length > 0 ? subjects.filter(q => q._id === x._id)[0]?.parentVaribaleId?.parentVaribaleId?.variableName : ""
                return (
                <>
                <div className='d-flex flex-column gap-2 align-items-center justify-content-start color'
                style={{
                  width : "12%",
                  padding: "15px"
                }}
                >
                  <div className='d-flex justify-content-center w-100' key={x.bookId} onClick={() => {
                    var newOpen = window.open(
                      `https://katbook.pmevidyakatbook.in/#/kampus/BookView/${Bookencrypted}`
                    );
                    if (newOpen == null || typeof (newOpen) == 'undefined') {
                      alert("Turn off your pop-up blocker!");
                    }
                    //   window.close();
                  }}>
                    <img style={{ width: "80%", cursor: "pointer" }} src={x.coverImage} alt='books' />
                  </div>
                  <div className='subjectname'>{x.book} {UserProfileData?.userTypeId?.usertype === "Teacher" ? StdID ? StdID : "" : ""}</div>
                  {/* <div className='d-flex  gap-2 align-items-center'>
                    <progress id="file" value="40" max="100"></progress>
                    <div className='value'>40%</div>
                  </div> */}
                </div>
                </>
                );
              })}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bookfetch