import React from "react";
import { Modal } from "react-bootstrap";
import "./ModalComponent.css";
import { AiOutlineClose } from "react-icons/ai";

const ModalComponent = (props) => {
  const {
    show,
    Title,
    onClick,
    Content,
    isGradient,
    size,
    dialogClassName,
    className,
    contentHeight,
    isNcert
  } = props;

  return (
    <div>
      {isNcert ? (
        <div>
          <Modal dialogClassName={dialogClassName} show={show} size={size} centered>
            <div className="d-flex flex-column ">
              <div>
                <Modal.Header
                  className={`d-flex justify-content-between align-items-center ${!isGradient && "modalComponent-header"
                    }`}
                >
                  <Modal.Title>
                    <div className={`${!isGradient && "modalComponent-title"}`}>
                      {"NCERT"}
                    </div>
                  </Modal.Title>
                  <div className="modalclose cursor" onClick={onClick}>
                    <AiOutlineClose className={`white cursor`} />
                  </div>
                </Modal.Header>
              </div>

              <div
                className={`pt-2 Modal_size ${contentHeight} ${isGradient ? "Gradient_Style" : "NonGradient_Style"
                  }`}
              >
                {/* <div className={`d-flex flex-column gap-3 ${className}`}>
                  {"Coming Soon..."}
                </div> */}
                <div className="d-flex justify-content-center align-items-center height_30">
                  <span className="h3">Coming Soon...</span>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <Modal dialogClassName={dialogClassName} show={show} size={size} centered>
          <div className="d-flex flex-column ">
            <div>
              <Modal.Header
                className={`d-flex justify-content-between align-items-center ${!isGradient && "modalComponent-header"
                  }`}
              >
                <Modal.Title>
                  <div className={`${!isGradient && "modalComponent-title"}`}>
                    {Title}
                  </div>
                </Modal.Title>
                <div className="modalclose cursor" onClick={onClick}>
                  <AiOutlineClose className={`white cursor`} />
                </div>
              </Modal.Header>
            </div>

            <div
              className={`pt-2 Modal_size ${contentHeight} ${isGradient ? "Gradient_Style" : "NonGradient_Style"
                }`}
            >
              <div className={`d-flex flex-column gap-3 ${className}`}>
                {Content}
              </div>
            </div>
          </div>
        </Modal>
      )
      }
    </div>
  );
};

ModalComponent.defaultProps = {
  show: false,
  Title: "",
  onClick: () => { },
  Content: "",
  isGradient: false,
  size: "lg",
  dialogClassName: "",
  className: "",
  isNcert: false,
};

export default ModalComponent;
