import "./App.css";
import "./Responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/header/Header";
import Home from "./components/Home/Home";
import Footer from "./components/footer/Footer";
import { Router, Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Katbook from "./components/Katbook/Katbook";
import { useEffect, useRef, useState } from "react";
import ModalComponent from "./components/modalComponent/ModalComponent";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Inventor from "./components/Inventor/Inventor";
import Bookfetch from "./components/BookFetch/Bookfetch";
import Public from "./Routes/Public";
import Private from "./Routes/Private";
// import {BrowserRouter as Router,Routes,Route } from 'react-router-dom'
import './components/header/Header.css'
import { TailSpin } from 'react-loader-spinner';
import { SessionStoreValue } from "./Helpers/utils";
import educationloader from "./Assests/educationloader.gif"
import PrivateRoute from "./PrivateRoute";

function App() {

  const ref = useRef(null);
  const ref2 = useRef(null);

    const RoutesClick = () => {
      navigate('/')
      setTimeout(() => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
      }, 100);
      
    };

    const NCERTClick = () => {
      navigate('/')
      setTimeout(() => {
        ref2.current?.scrollIntoView({behavior: 'smooth'});
      }, 100);
      
    };

  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticate = SessionStoreValue();
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
    setTimeout(async () => {
      setIsLoading(false)
      },2000);

  }, []);



  useEffect(() => {
    var lastScrollTop;
    let navbar = document.getElementById("navbar");
    console.log(navbar, "qqqqqqq");
    const handleScroll = () => {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        navbar.style.top = "-80px";
      } else {
        navbar.style.top = "0px";
      }
      lastScrollTop = scrollTop;
    };

    // Check screen width before adding the scroll event listener
    const addScrollListener = () => {
      if (navbar !== null && navbar !== undefined) {
        if (window.innerWidth > 600) {
          window.addEventListener("scroll", handleScroll);
        }
      }
    };

    addScrollListener(); // Add the listener initially

    // Cleanup: Remove the scroll event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   // Scroll to the top of the page when the component mounts
  //   window.scrollTo(0, 0);

  //   // Check if the current pathname is not the root path ('/')
  //   if (window.location.pathname !== "/") {
  //     // Redirect to the home page
  //     window.location.href = "/";
  //   }
  // }, []);

  // const handleTabClickHeader = (tab) => {
  //   setActiveTabHeader(tab);
  // };

  // const [activeTabHeader, setActiveTabHeader] = useState("Home");

  // const [studentregisterModal, setStudentRegisterModal] = useState(false);

  // const [ParentregisterModal, setParentRegisterModal] = useState(false);
  // const [TeacherregisterModal, setTeacherregisterModal] = useState(false);
  console.log(localStorage.getItem('token'), "isAuthenticate")

  return (
    <>
      {isLoading ? (
          // Display loader while loading
          // <div>Loading...</div>
          <div className="loader-container">
          {/* <TailSpin
            color="#00BFFF"
            height={80}
            width={80}
            timeout={10000}
          /> */}
           <img src={educationloader} alt="Loader" />
        </div>
        ) : (
          <>
      <Routes>
        <Route path="/" element={localStorage.getItem('token') === null ? <Home Rootsref={ref} Ncertref={ref2} RoutesClick={RoutesClick} NcertClick={NCERTClick} /> : <Navigate to="/bookview" />} />
        {/* <Route exact path="/" element={<Home />} /> */}
        <Route path="/inventor" element={localStorage.getItem('token') === null ? <Inventor RoutesClick={RoutesClick} NcertClick={NCERTClick} /> : <Navigate to="/bookview" />} />
        <Route path="/katbook" element={localStorage.getItem('token') === null ? <Katbook RoutesClick={RoutesClick} NcertClick={NCERTClick} /> : <Navigate to="/bookview" />} />
        {/* <PrivateRoute path="/bookview" element={<Bookfetch />} /> */}
        <Route path="/bookview" element={localStorage.getItem('token') ? <Bookfetch /> : <Navigate to="/" />}
        />
      </Routes>
      <ToastContainer />
      </>
        )}
    </>
  );
}

export default App;
