import React, { Fragment, useState, useEffect } from "react";
import "./Header.css";
import emblem from "../../Assests/emblem.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ModalComponent from "../modalComponent/ModalComponent";
import student from "../../Assests/SM743846 1.png";
import Parents from "../../Assests/SM876266 1.png";
import Teachers from "../../Assests/SM876266 1 (1).png";
import Input from "../input/Input";
import OtpInput from "react-otp-input";
import MediaQuery from "react-responsive";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { getmedium, getstandardbyMID, checkuserid, createTeacher, createStudent, useridsuggestion, createParent, getuseridDetails, userlogin ,otpverfication,otpgenerate,forgetpwd, addStudentBook, getUserListFromUsers} from '../../Services/ApiService';
import Select from 'react-select';
import { FaExclamationCircle } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import Public from "../../Routes/Public";
import Private from "../../Routes/Private";
import moment from "moment";

// import { useDispatch } from "react-redux";
const Header = ({ RefreshSubjects, addbookOpen, setaddbookOpen, userAuth, RoutesClick, NcertClick, registerModal, setRegisterModal, activeTab, handleTabClick, studentregisterModal, setStudentRegisterModal, ParentregisterModal, setParentRegisterModal, TeacherregisterModal, setTeacherregisterModal }) => {
    
    const [secondsPassed, setSecondsPassed] = React.useState(0);
    const [timerActive, setTimerActive] = React.useState(true);
    const UserProfileData = JSON.parse(localStorage.getItem('userData'));
    const UserProfileType = JSON.parse(localStorage.getItem('userType'));
    const [StudentAddbookModal, setStudentAddbookModal] = React.useState(false);
    const [TeacherAddbookModal, setTeacherAddbookModal] = React.useState(false);
    const [UserValidate, setUserValidate] = React.useState(false);
    const [selecteduserId, setselecteduserId] = React.useState("");
    const [UserValidateList, setUserValidateList] = React.useState({});
    
    const startTime = 60 * 2;
    const timeLeft = startTime - (secondsPassed % startTime);
  
    const minuteCount = ~~(timeLeft / 60);
    const secondsCount = timeLeft % 60;
    const countdown = `${minuteCount}:${secondsCount.toLocaleString("en-US", {
      minimumIntegerDigits: 2
    })}`;

    useEffect(() => {
        console.log(timeLeft,"timer");
      if (timerActive && timeLeft > 1) {
        const timer = setInterval(() => {
            console.log(secondsPassed,"timer");
          setSecondsPassed(sp => sp + 1);
        }, 1000);
  
        return () => clearInterval(timer);
      } else if (timeLeft === 1) {
        // setTimerActive(false);
        setTimeout(() => {
            setTimerActive(false);
        }, 1000);
      }
    }, [timerActive, timeLeft]);
    
    const [isLoadingreg, setisLoadingreg] = useState(false);
    const [OtpModal, setOtpModal] = useState(false);
    const [roleName, setroleName] = useState("");
    const [isResend, setisResend] = useState(false);
    const [ForgotpwdModal, setForgotpwdModal] = useState(false);
    const [pwdModal, setPwdModal] = useState(false);

    const [routeModal, setRouteModal] = useState(false);
    const [ncertModal, setNcertModal] = useState(false);

    const [LoginModal, setLoginModal] = useState(false);
    const [LoginRoleModal, setLoginRoleModal] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpPwd, setOtpPwd] = useState("");
    const [button, setbutton] = useState();

    const [menuOpen, setMenuOpen] = useState(false);
    const [menuOpenContent, setMenuOpenContent] = useState(false);
    const [mediumarray, setmediumarray] = useState([]);
    const [stdarray, setstdarray] = useState([]);
    const [subarray, setsubarray] = useState([]);
    const [subteacherarray, setsubteacherarray] = useState([]);
    const [stdteacherarray, setstdteacherarray] = useState([]);
    const [usernamesuggestion, setusernamesuggestion] = useState([]);
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    const [selectedSuggestionteach, setSelectedSuggestionteach] = useState(null);
    const [selectedSuggestionparent, setSelectedSuggestionparent] = useState(null);
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [userdetails, setuserdetails] = useState([]);
    const [mobilenumber, setmobilenumber] = useState("");
    const [checkusernotify, setcheckusernotify] = useState("");
    const [checksuccessusernotify, setsuccesscheckusernotify] = useState("");
    const [loginmobilenumber, setloginmobilenumber] = useState("");
    const [registertype, setregistertype] = useState("");
    const notify = () => toast.error("Passwords does not match.");
    const successRegister = () => toast.success("Successfully Register.");
    const [checktecpwd, setchecktecpwd] = useState('');
    const [checkstupwd, setcheckstupwd] = useState('');
    const [checkparpwd, setcheckparpwd] = useState('');
    // const dispatch = useDispatch();
    const options = [
        [
            { value: "VIII", label: "VIII" },
            { value: "IX", label: "IX" },
            { value: "X", label: "X" },
            { value: "XI", label: "XI" },
            { value: "XII", label: "XII" },
        ],
        [
            { value: "Male", label: "Male" },
            { value: "Female", label: "Female" },
            { value: "other", label: "other" },
        ],
        [
            { value: "Tamil", label: "Tamil" },
            { value: "English", label: "English" },
            // { value: "other", label: "other" },
        ],
        [
            { value: "Father", label: "Father" },
            { value: "Mother", label: "Mother" },
            { value: "Gaurdian", label: "Gaurdian" },
        ],
    ];
    // const options = [
    //     { value: "chocolate", label: "Chocolate" },
    //     { value: "strawberry", label: "Strawberry" },
    //     { value: "vanilla", label: "Vanilla" },
    // ];
    const selctGaurd = [
        { value: "Father", label: "Father" },
        { value: "Mother", label: "Mother" },
        { value: "Guardian", label: "Guardian" }, // Corrected typo here
    ];

    const navigate = useNavigate();
    const [gaurdShow, setGaurdShow] = useState(false);
    const [userdetailshow, setuserdetailshow] = useState(false);

    const guardOnChange = (e) => {
        if (e.value === "Father" || e.value === "Mother") {
            setGaurdShow(false);
        } else if (e.value === "Guardian") {
            // setparentState({
            //     gender: "",
            // });
            setGaurdShow(true);
        }
    };

    const DataSuggestion = [
        {
            name: "SAkashpandurangan07",
        },
        {
            name: "SAkashpandurangan72",
        },
        {
            name: "SAkashpandurangan7299",
        },
    ];

    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        birth: "",
        gender: "",
        number: "",
        schoolName: "",
        MediumInstruction: "",
        Standard: "",
        Subject: [],
        user: "",
        pwd: "",
        confirmPwd: "",
        suggestion: false,
        suggestionCard: false,
    });

    const [stateTeacher, setStateTeacher] = useState({
        firstName: "",
        lastName: "",
        birth: "",
        gender: "",
        number: "",
        schoolName: "",
        MediumInstruction: [],
        Standard: [],
        Subject: [],
        user: "",
        pwd: "",
        confirmPwd: "",
        suggestion: false,
        suggestionCard: false,
    });

    // const [parentState, setparentState] = useState({
    //   firstName: "",
    //   lastName: "",
    //   birth: "",
    //   number: "",
    //   schoolName: "",
    //   Mr: [],
    //   StudentId: "",
    //   user: "",
    //   pwd: "",
    //   confirmPwd: "",
    //   suggestion: false,
    //   suggestionCard: false,
    // });

    const [LoginState, setLoginState] = useState({
        user: "",
        pwd: "",
    });

    const [enterPwd, setEnterPwd] = useState({
        pwd: "",
        confirmPwd: "",
    });

    const emptyStateParent = () => {
        setparentState({
            firstName: "",
            lastName: "",
            birth: "",
            number: "",
            schoolName: "",
            Mr: [],
            StudentId: "",
            user: "",
            pwd: "",
            confirmPwd: "",
            relation: "",
            gender: "",
            StudentId: "",
        });
        setOtp("");
        setuserdetails([]);
        setGaurdShow(false);
        setsuccesscheckusernotify("");
        setcheckparpwd("");
    };

    const emptyState = () => {
        setState({
            firstName: "",
            lastName: "",
            birth: "",
            gender: [],
            number: "",
            schoolName: "",
            MediumInstruction: [],
            Standard: [],
            Subject: "",
            user: "",
            pwd: "",
            confirmPwd: "",
        });
        setOtp("");
        setSelectedSubjects([]);
        setsubarray([]);
        setsuccesscheckusernotify("")
        setcheckstupwd('');
    };

    const emptyStateTeacher = () => {
        setStateTeacher({
            firstName: "",
            lastName: "",
            birth: "",
            gender: [],
            number: "",
            schoolName: "",
            MediumInstruction: [],
            Standard: [],
            Subject: "",
            user: "",
            pwd: "",
            confirmPwd: "",
        });
        setOtp("");
        setsubteacherarray([]);
        setsuccesscheckusernotify("");
        setchecktecpwd('');
    };
    const secretKey = 'KampusNcrt';
    const key = "KPOSTKatBooks#$*(@#)!!";
    useEffect(() => {
        getMediumFunc();
    }, []);

    const [parentState, setparentState] = useState({
        firstName: "",
        lastName: "",
        birth: "",
        number: "",
        schoolName: "",
        relation: "",
        gender: "",
        StudentId: "",
        user: "",
        pwd: "",
        confirmPwd: "",
        suggestion: false,
        suggestionCard: false,
        checkvalue:false,
    });

    const isFormValid = () => {
        // Check if all required fields are filled
        return Object.values(state).every((value) => Boolean(value));
    };

    const isFormValidLogin = () => {
        // Check if all required fields are filled
        return Object.values(LoginState).every((value) => Boolean(value));
    };

    const isFormValidNewPwd = () => {
        // Check if all required fields are filled
        return Object.values(enterPwd).every((value) => Boolean(value));
    };

    const isFormValidTeacher = () => {
        // Check if all required fields are filled
        return Object.values(stateTeacher).every((value) => Boolean(value));
    };

    // const isFormValidParent = () => {
    // Check if all required fields are filled
    //   return Object.values(parentState).every((value) => Boolean(value));
    // };    

    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [showAllSubjects, setShowAllSubjects] = useState(false);
   

    const handleConfirmPasswordChangeTec = (e) => {
        const newConfirmPwd = e.target.value;
        setStateTeacher({ ...stateTeacher, confirmPwd: newConfirmPwd });
        // if (stateTeacher.pwd && newConfirmPwd !== stateTeacher.pwd) {
        //     setchecktecpwd('Passwords do not match');
        // } else {
        //     setchecktecpwd('');
        // }
    };
    const handleConfirmPasswordChangeStu = (e) => {
        const newConfirmPwd = e.target.value;
        setState({ ...state, confirmPwd: newConfirmPwd });
        // if (state.pwd && newConfirmPwd !== state.pwd) {
        //     setcheckstupwd('Passwords do not match');
        // } else {
        //     setcheckstupwd('');
        // }
    };
    const handleConfirmPasswordChangePar = (e) => {
        const newConfirmPwd = e.target.value;
        setparentState({ ...parentState, confirmPwd: newConfirmPwd });
        // if (parentState.pwd && newConfirmPwd !== parentState.pwd) {
        //     setcheckparpwd('Passwords do not match');
        // } else {
        //     setcheckparpwd('');
        // }
    };
    const handleCheckboxChange = (selectedSubject) => {
        const { label, value } = selectedSubject;

        setSelectedSubjects((prevSelectedSubjects) => {
            const isAlreadySelected = prevSelectedSubjects.some(
                (subject) => subject.label === label && subject.value === value
            );

            if (isAlreadySelected) {
                // If already selected, remove the subject
                return prevSelectedSubjects.filter(
                    (subject) => !(subject.label === label && subject.value === value)
                );
            } else {
                // If not selected, add the subject
                return [...prevSelectedSubjects, { label, value }];
            }
        });
    };
    const loginuserfunc = async () => {

        console.log(isNaN(LoginState.user),"isNaN");
        try {
            let bod = {
                userId: selecteduserId ? selecteduserId : (roleName === "Student" ? (isNaN(LoginState.user) ? `S${LoginState.user}` : LoginState.user) : roleName === "Teacher" ? (isNaN(LoginState.user) ? `T${LoginState.user}` : LoginState.user)  : LoginState.user),
                password: LoginState.pwd,
                role: roleName

            };

            let res = await userlogin(bod);

            if (res?.success === true) {
                <Public isLogged={true} />
               
                
                const encryptedMedium = CryptoJS.AES.encrypt(
                    JSON.stringify(res.value.medium),
                    secretKey
                  ).toString();
                  const encryptedSubjects = CryptoJS.AES.encrypt(
                    JSON.stringify(res.value.subjects),
                    secretKey
                  ).toString();
                  const encryptedStandard = CryptoJS.AES.encrypt(
                    JSON.stringify(res.value.standard),
                    secretKey
                  ).toString();
                  const encryptedCode = CryptoJS.AES.encrypt(
                    JSON.stringify(res.value.code),
                    secretKey
                  ).toString();
                  const encryptedUserID = CryptoJS.AES.encrypt(
                    JSON.stringify(res.value.userId),
                    secretKey
                  ).toString();
                  const { firstName, lastName } = res.value;
                  const username = `${firstName} ${lastName}`;
                //   const userCode = res.value.code;
                //   const userId = res.value.userId;
                  localStorage.setItem('userID', encryptedUserID);
                  localStorage.setItem('userCode', encryptedCode);
                  localStorage.setItem('username', username);
                localStorage.setItem('encryptedMedium', encryptedMedium);
                localStorage.setItem('encryptedSubjects', encryptedSubjects);
                localStorage.setItem('encryptedStandard', encryptedStandard);
                localStorage.setItem('token', res?.token);
                localStorage.setItem('IsUserAuth', true);
                localStorage.setItem('userData', JSON.stringify(res?.value));
                localStorage.setItem('userType',JSON.stringify( res?.value.userTypeId));
                // sessionStorage.setItem('token', res?.token);
                sessionStorage.setItem("user", JSON.stringify(res?.value));
                
                toast.success(res?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                <Private isLogged={true} />
                setLoginModal(false);
                setLoginState("")
                //  window.location.href = "/bookview";
                navigate("/bookview");
                setroleName("")
            }
            else {
                // console.log(res.error, res, "testtstst")
                toast.error(res?.error, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            console.log(error)
        }

    };


    const getMediumFunc = async () => {

        try {
            let res = await getmedium();
            if (res.success) {

                let data = res.data.map(medium => ({
                    label: medium.variableName,
                    value: medium._id,
                }));

                console.log(data,"ddddddddddddd")
                setmediumarray(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleaddbookMediumChange = async(e) => {
        if(e.length === 0){
            setState({ ...state, Standard: "", MediumInstruction: e });
        } else {
            
            var medium_data = e.map((item) => { return item.value })
            let bod = {
                "variableIds": medium_data
            }
            let res = await getstandardbyMID(bod, "Student");
            if (res?.success === true) {
                let resStadData = res.data.filter(ss => String(ss.variableName) === String(UserProfileData.standard[0]?.variableName));
                let data;
                    data = resStadData.map(std => ({
                        label: std.variableName+" Std - "+std.class+"",
                        value: std._id,
                        variableN: std.variableName
                    }))
                setstdarray(data);
                setstdteacherarray(data);
                getsubjectlist(data);
            }
            setState({ ...state, MediumInstruction: e, Standard: stdarray });
        }
    }

    const handleMediumChange = async(e) => {
        console.log(e,"Student Data");
        if(e.length === 0){
            setState({ ...state, Standard: "", MediumInstruction: e });
        } else {
            setState({ ...state, MediumInstruction: e });
        }
        if(e.length > 0){
            getStustandard(e);
        }
        
    }

    

    const getStustandard = async (mid, type) => {

        var medium_data = mid.map((item) => { return item.value })

        console.log(medium_data,"ddddddddddddd")

        try {
            // let bod = {
            //     "variableIds": [
            //         mid.value
            //     ]
            // }
            let bod = {
                "variableIds": medium_data
            }
            let res = await getstandardbyMID(bod, type);

            if (res?.success === true) {
                console.log(res, "aftersuccess")
                console.log(userAuth, "aftersuccess")
                let data;
                if(userAuth === "true"){
                    let resStadData = res.data.filter(ss => String(ss.variableName) === String(UserProfileData.standard[0]?.variableName));
                    console.log(resStadData, "aftersuccess")
                    data = resStadData.map(std => ({
                        label: std.variableName+" Std - "+std.class+"",
                        value: std._id,
                        variableN: std.variableName
                    }))
                } else {
                    data = res.data.map(std => ({
                        label: std.variableName+" Std - "+std.class+"",
                        value: std._id,
                        variableN: std.variableName
                    }))
                }
                console.log(data, "aftersuccess")
                setstdarray(data);
            }

        } catch (error) {
            console.log(error)
        }

    };

    const handleTeaMediumChange = async(e) => {
        console.log(e,"Student Data");
        if(e.length === 0){
            setStateTeacher({ ...stateTeacher, MediumInstruction: e, Standard: [] });
        } else {
            setStateTeacher({ ...stateTeacher, MediumInstruction: e });
        }
        if(e.length > 0){
            getTeastandard(e);
        }
    }

    const getTeastandard = async (mid, type) => {

        var medium_data = mid.map((item) => { return item.value })

        try {
            // let bod = {
            //     "variableIds": [
            //         mid.value
            //     ]
            // }
            let bod = {
                "variableIds": medium_data
            }
            let res = await getstandardbyMID(bod, type);

            if (res?.success === true) {
                console.log(res, "aftersuccess")
                let data = res.data.map(std => ({
                    label: std.variableName+" Std - "+std.class+"",
                    value: std._id,
                }))
                setstdteacherarray(data);
            }

        } catch (error) {
            console.log(error)
        }

    };
    const getsubjectlist = async (sid, type, stdtype) => {
        console.log(sid, stdtype, "testesid")

        var standard_data = sid.map((item) => { return item.value })

        console.log(standard_data,"ddddddddddddd")

        try {
            if (stdtype !== "teacherstd") {
                // let bod = {
                //     "variableIds": [
                //         sid.value
                //     ]
                // }
                let bod = {
                    "variableIds": standard_data
                }
                let res = await getstandardbyMID(bod, type);

                if (res?.success === true) {
                    console.log(res, "aftersuccess");
                    const subjectsForSid = res.data[sid.label];

                    let data = res.data.map(std => ({
                        label: std.variableName,
                        value: std._id,
                    }));
                    console.log(data, "setsubid")
                    setsubarray(data);

                    // setsubarray(res.data);
                    
                }
            }
            else {
                console.log(stateTeacher.Standard, sid, "stateTeacher")
                const Stdteachervalue = sid.map(standard => standard.value);
                console.log(Stdteachervalue, "stdbod")
                // const Stdteacherlabel = sid.map(standard => standard.label);
                let bod = {
                    "variableIds": Stdteachervalue


                }
                console.log(bod, "stdbod")
                let res = await getstandardbyMID(bod, type);

                // if (res?.success === true) {
                //     console.log(res.data, "aftersuccessstd");
                //     // const subjectsForSid = res.data[Stdteacherlabel];

                //     // let data = subjectsForSid.map(std => ({
                //     //     label: std.variableName,
                //     //     value: std._id,
                //     // }));

                //     // setsubteacherarray(data);
                // }
                if (res?.success === true) {
                    //     const allVariables = Object.values(res.data)
                    //     .map(subjects => subjects.map(subject => ({
                    //         label: subject.variableName,
                    //         value: subject._id,
                    //     })))
                    //     .flat();

                    console.log(res.data, "allveriable");
                    setsubteacherarray(res.data);
                } else {
                    setsubteacherarray([]);
                }
            }

        } catch (error) {
            console.log(error)
        }

    };
    const checkuseridfun = async (type) => {

        let body;

        if (type === 'student') {
            body = `S${state.user}`;
        } else if (type === "parent") {
            // body = parentState.user;
            body = parentState.relation.value === "Father"
                ? `F${parentState.user}`
                : parentState.relation.value === "Mother"
                    ? `M${parentState.user}`
                    : parentState.relation.value === "Guardian"
                        ? `G${parentState.user}`
                        : `${parentState.user}`;
        }
        else {
            body = `T${stateTeacher.user}`;
        }
        try {

            let res = await checkuserid(body);

            if (res?.success === true) {
                setsuccesscheckusernotify(res?.message)
                setcheckusernotify("")
            }else{
                setsuccesscheckusernotify("")
                setcheckusernotify(res?.error)
                if (type === 'student') {
                setState({
                    ...state,
                    suggestion: true,
                });
                } else if (type === "parent") {
                    setparentState({
                        ...parentState,
                        suggestion: true,
                    });
                } else {
                    setStateTeacher({
                        ...stateTeacher,
                        suggestion: true,
                    });
                }
            }

        } catch (error) {
            console.log(error)
        }

    };
    const useridsuggestionlist = async (type) => {
        console.log(stateTeacher,"stateTeacher");
        console.log(type,"stateTeacher");
        try {

            let bod;

            if (type === 'student') {

                bod = {
                    firstName: state.firstName,
                    lastName: state.lastName,

                };
            } else if (type === 'parent') {
                bod = {
                    firstName: parentState.firstName,
                    lastName: parentState.lastName,

                };
            }

            else {

                bod = {
                    firstName: stateTeacher.firstName,
                    lastName: stateTeacher.lastName,

                };
            }
            let res = await useridsuggestion(bod);

            if (res?.success === true) {
                setusernamesuggestion(res.data);
                console.log(res, "useridsugg")

            }

        } catch (error) {
            console.log(error)
        }

    };

    
    const handleTeacherstdChangeForAddBook = (selectedOptions) => {
        setStateTeacher({ ...stateTeacher, Standard: selectedOptions });
        getsubjectlist(selectedOptions, "teacher", "teacherstd");
    };

    const handleTeacherstdChange = (selectedOptions) => {
        setSelectedSubjectsTeach([])
        setStateTeacher({ ...stateTeacher, Standard: selectedOptions });
        getsubjectlist(selectedOptions, "teacher", "teacherstd");
    };
    const handleStudentSubjectChange = (selectedOptions) => {
        setState({ ...state, Subject: selectedOptions });
    };
    const addTeacher = async () => {
        console.log(selectedSubjectsTeach, "testsub")
        const medium_Studata = stateTeacher.MediumInstruction.map((item) => { return item.value })
        const Standardidarr = stateTeacher.Standard.map(std => std.value);
        const selectedSubjectIds = Object.keys(selectedSubjectsTeach).filter(
            (subjectId) => selectedSubjectsTeach[subjectId]
        );
        try {

            let bod = {
                firstName: stateTeacher.firstName.trim(),
                lastName: stateTeacher.lastName.trim(),
                mobileNo: stateTeacher.number,
                dateOfBirth: stateTeacher.birth,
                gender: stateTeacher.gender.value,
                schoolName: stateTeacher.schoolName,
                medium: medium_Studata,
                standard: Standardidarr,
                userId: `T${stateTeacher.user}`,
                password: stateTeacher.pwd,
                subjects: Object.keys(selectedSubjectsTeach),
            };
            let res = await createTeacher(bod);
            if (res?.success === true) {
                toast.success(res?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setRegisterModal(false);
                setTeacherregisterModal(false);
                emptyStateTeacher();
                setOtpModal(false);
                console.log(res.message, "message")
                setroleName("")
            } 
              else {
                //toast.error(<Danger body={"Something error in create API"} />);
                toast.error(res?.error, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setRegisterModal(false);
                setTeacherregisterModal(true);
                console.log(res.error, "message")
            
            }
        } catch (e) {
            //console.log(e);
        }
    };

    const updateBooks = async (type) => {
        console.log(Object.keys(selectedSubjects),"JJJJJJJJJJJJJJ");
        console.log(Object.keys(selectedSubjectsTeach),"JJJJJJJJJJJJJJ");
        let Subjectidarr;
        if(type === "student"){
        Subjectidarr = selectedSubjects.map(subject => subject.value);
        }
        try {
            let bod = {
                bookIds: type === "student" ? Subjectidarr : Object.keys(selectedSubjects).length < Object.keys(selectedSubjectsTeach).length ? Object.keys(selectedSubjectsTeach) : [],
            };

            let res = await addStudentBook(UserProfileData?._id,bod);
            if (res?.success === true) {

                const encryptedMedium = CryptoJS.AES.encrypt(
                    JSON.stringify(res.value.medium),
                    secretKey
                  ).toString();
                  const encryptedSubjects = CryptoJS.AES.encrypt(
                    JSON.stringify(res.value.subjects),
                    secretKey
                  ).toString();
                  const encryptedStandard = CryptoJS.AES.encrypt(
                    JSON.stringify(res.value.standard),
                    secretKey
                  ).toString();
                  const encryptedCode = CryptoJS.AES.encrypt(
                    JSON.stringify(res.value.code),
                    secretKey
                  ).toString();
                  const encryptedUserID = CryptoJS.AES.encrypt(
                    JSON.stringify(res.value.userId),
                    secretKey
                  ).toString();
                  const { firstName, lastName } = res.value;
                  const username = `${firstName} ${lastName}`;
                //   const userCode = res.value.code;
                //   const userId = res.value.userId;
                  localStorage.setItem('userID', encryptedUserID);
                  localStorage.setItem('userCode', encryptedCode);
                  localStorage.setItem('username', username);
                localStorage.setItem('encryptedMedium', encryptedMedium);
                localStorage.setItem('encryptedSubjects', encryptedSubjects);
                localStorage.setItem('encryptedStandard', encryptedStandard);
                localStorage.setItem('token', res?.token);
                localStorage.setItem('IsUserAuth', true);
                localStorage.setItem('userData', JSON.stringify(res?.value));
                localStorage.setItem('userType',JSON.stringify( res?.value.userTypeId));
                // sessionStorage.setItem('token', res?.token);
                sessionStorage.setItem("user", JSON.stringify(res?.value));

                toast.success(res?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                emptyState();
                RefreshSubjects(res.value.subjects.map(subject => subject._id))
            } else {
                toast.error(res?.error, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            setStudentAddbookModal(false);
            setTeacherAddbookModal(false);
        } catch (e) {
            console.log(e, "error");
        }

    }
    const addStudent = async () => {
        const Subjectidarr = selectedSubjects.map(subject => subject.value);
        console.log(selectedSubjects, Subjectidarr, "testsub")
        const medium_Studata = state.MediumInstruction.map((item) => { return item.value })
        const standard_Studata = state.Standard.map((stand) => { return stand.value })
        console.log(state.Standard,"wwwwwwwwwwww");
        console.log(standard_Studata,"wwwwwwwwwwww");
        try {

            let bod = {
                firstName: state.firstName.trim(),
                lastName: state.lastName.trim(),
                mobileNo: state.number,
                dateOfBirth: state.birth,
                gender: state.gender.value,
                schoolName: state.schoolName,
                medium: medium_Studata,
                standard: standard_Studata,
                userId: `S${state.user}`,
                password: state.pwd,
                subjects: Subjectidarr,
            };
            console.log(bod, "testbod")
            let res = await createStudent(bod);
            if (res?.success === true) {
                toast.success(res?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setRegisterModal(false);
                setStudentRegisterModal(false);
                emptyState();
                setOtpModal(false);
                console.log(res.message, "message")
                setroleName("")
            } else {
                toast.error(res?.error, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setRegisterModal(false);
                setStudentRegisterModal(true);
            }

        } catch (e) {
            console.log(e, "error");
        }
    };
    const addParent = async () => {

        try {

            let bod = {
                firstName: parentState.firstName.trim(),
                lastName: parentState.lastName.trim(),
                mobileNo: parentState.number,
                dateOfBirth: parentState.birth,
                studentId: `S${parentState.StudentId}`,
                password: parentState.pwd,
                // userId: parentState.user,
                userId:
                    parentState.relation.value === "Father"
                        ? `F${parentState.user}`
                        : parentState.relation.value === "Mother"
                            ? `M${parentState.user}`
                            : parentState.relation.value === "Guardian"
                                ? `M${parentState.user}`
                                : `P${parentState.user}`,
                gender: parentState.relation.value === "Father"
                    ? "Male"
                    : parentState.relation.value === "Mother"
                        ? "Female"
                        : parentState.relation.value === "Guardian"
                            ? parentState.gender.value
                            : "Male",
              relation: parentState.relation.value,
            };
            console.log(bod, "addparent")
            let res = await createParent(bod);
            if (res?.success === true) {
                toast.success(res?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setuserdetailshow(false);
                setRegisterModal(false);
                setParentRegisterModal(false);
                emptyStateParent();
                setOtpModal(false);
                console.log(res.message, "message")
                setroleName("")
            } else {
                //toast.error(<Danger body={"Something error in create API"} />);
                toast.error(res?.error, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setRegisterModal(false);
                setParentRegisterModal(true);
                //    setuserdetailshow(false);
                console.log(res.error, "message")
            }
        } catch (e) {
            console.log(e, "error");
        }
    };
    const handleSelectAll = () => {
        const allSubjects = subarray.map((x) => ({ ...x, isChecked: true }));
        setSelectedSubjects(allSubjects);
    };


    const handleClearAll = () => {
        setSelectedSubjects([]);
    };

    const handleUserCheckboxChange = () => {
        setparentState((prevState) => ({
          ...prevState,
          checkvalue: !prevState.checkvalue, 
        }));
    
       
          getuserdetailsbyId();
    
      };
    const handleButtonClick = () => {
        setShowAllSubjects(!showAllSubjects);
    };
    const getuserdetailsbyId = async (id) => {
        try {
            let body = `S${parentState.StudentId}`;
           
            let res = await getuseridDetails(body);
            if (res.success) {

                console.log(res.data, "getuserdata")
                setuserdetailshow(true);
                setuserdetails(res.data);
                // toast.success(res?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                // });
            } else {
                setuserdetailshow(false);
                toast.error(res?.error, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const generateResendOTPfunc = async (type,regtype,role) => {
        try {

            const userInput = LoginState.user;
            const isMobileNumber = /^\d+$/.test(userInput);
    
        const mobileNumber = regtype === 'Stu' ? state.number : regtype === 'Par' ? parentState.number : regtype === 'Tec' ? stateTeacher.number : userInput;
            let bod = {
                mobile: mobileNumber,
                type: type,
                role: role,
                userid:isMobileNumber ? null : userInput
            };
            console.log(bod, "testbod")
            let res = await otpgenerate(bod);
            if (res?.success === true) {
                
                toast.success(res?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setloginmobilenumber(res.mobile)

                setroleName(role)
                setisResend(false)
            } else {
                toast.error(res?.error, {
                    position: toast.POSITION.TOP_RIGHT,
                });
               
            }
            setisLoadingreg(false)
        } catch (e) {
            setisLoadingreg(false)
            console.log(e, "error");
        }
    }

    const generateOTPfunc = async (type,regtype,role) => {
        setisLoadingreg(true)
        try {
           

            const userInput = selecteduserId !== "" ? selecteduserId : LoginState.user;
            const isMobileNumber = /^\d+$/.test(userInput);
    
        const mobileNumber = regtype === 'Stu' ? state.number : regtype === 'Par' ? parentState.number : regtype === 'Tec' ? stateTeacher.number : userInput;
            let bod = {
                mobile: mobileNumber,
                type: type,
                role: role,
                userid:isMobileNumber ? null : userInput
            };
            console.log(bod, "testbod")
            let res = await otpgenerate(bod);
            if (res?.success === true) {
                
                toast.success(res?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setloginmobilenumber(res.mobile)

                if(type === "reset"){
                    setLoginModal(false);
                    setForgotpwdModal(true);
                } else {
                    setOtpModal(true);
                }
                setroleName(role)
            } else {
                toast.error(res?.error, {
                    position: toast.POSITION.TOP_RIGHT,
                });
               
            }
            setisLoadingreg(false)
        } catch (e) {
            setisLoadingreg(false)
            console.log(e, "error");
        }
       
    };

    const forgototpVerfication = async () => {

        try {

            let bod = {
                mobilenumber: loginmobilenumber,
                otp: otpPwd,
                type: "reset",
                role: roleName
            };

            let res = await otpverfication(bod);
            if (res?.success === true) {
                setForgotpwdModal(false);
                setPwdModal(true);
                setOtpPwd("")
            } else {
                toast.error(res?.error, {
                    position: toast.POSITION.TOP_RIGHT,
                });
               
            }
        } catch (e) {
            console.log(e, "error");
        }

    }


    const userotpVerfication = async (type,regtype) => {
      
        try {

            let bod = {
                mobilenumber: mobilenumber,
                otp: otp,
                type: type,
                role: roleName
            };
            console.log(bod, "testbod")
            let res = await otpverfication(bod);
            if (res?.success === true) {
                // toast.success(res?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                // });
                setOtpModal(false);
                setOtp("");
                registerAndVerify(regtype)
            } else {
                setOtpModal(true);
                toast.error(res?.error, {
                    position: toast.POSITION.TOP_RIGHT,
                });
               
            }
        } catch (e) {
            console.log(e, "error");
        }
       
    };
    //abhinaya
    const studentregisterfunc = () => {
        setRegisterModal(false);
        setStudentRegisterModal(true);
        generateOTPfunc("registration","Stu","Student")
    };
    const parentregisterfunc = () => {
        setRegisterModal(false);
        setParentRegisterModal(true);
        generateOTPfunc("registration","Par","Parent")
    };
    const teacherregisterfunc = () => {
        setRegisterModal(false);
        setTeacherregisterModal(true);
        generateOTPfunc("registration","Tec","Teacher")
    };
    const registerAndVerify = async (regtype) => {
        try {
            switch (regtype) {
                case 'student-registration':
                    await addStudent();
                    break;
                case 'teacher-registration':
                    await addTeacher();
                    break;
                case 'parent-registration':
                    await addParent();
                    break;
                default:
                    console.error('Invalid registration type');
            }
            // setOtpModal(true);
           
        } catch (error) {
            console.error('Error during registration:', error);
        }
    };
    const pwdResetfunc = async () => {


        try {
            let bod = {
                userId: selecteduserId ? selecteduserId : LoginState.user,
                password: enterPwd.pwd,
                role: roleName

            };

            let res = await forgetpwd(bod);

            if (res?.success === true) {
                toast.success(res?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setPwdModal(false)
                setEnterPwd("")
                setroleName("")
                setselecteduserId("")
                setUserValidate(false)
                setUserValidateList({})
                setLoginState({ ...LoginState, user: "" });
                setEnterPwd({ ...enterPwd, pwd: "", confirmPwd: "" });
            }
            else {
                console.log(res.error, res, "testtstst")
                toast.error(res?.error, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            console.log(error)
        }

    };
    const [selectedSubjectsTeach, setSelectedSubjectsTeach] = useState([]);
    const [showAllSubjectsTeach, setShowAllSubjectsTeach] = useState(false);
    const handleCheckboxChangeTeach = (id) => {
        const updatedSelectedSubjects = { ...selectedSubjectsTeach };
        console.log(updatedSelectedSubjects,"updatedSelectedSubjects");
        if(!updatedSelectedSubjects[id] === false){
            delete updatedSelectedSubjects[id];
        } else {
            updatedSelectedSubjects[id] = !updatedSelectedSubjects[id];
        }
        setSelectedSubjectsTeach(updatedSelectedSubjects);
        console.log(Object.keys(selectedSubjectsTeach),"updatedSelectedSubjects");
    };

    const handleSelectAllTeach = () => {
        const allSelected = Object.values(subteacherarray)
          .flat()
          .reduce((acc, x) => {
            acc[x._id] = true;
            return acc;
          }, {});
        setSelectedSubjectsTeach(allSelected);
      };

    const handleClearAllTeach = () => {
        setSelectedSubjectsTeach([]);
    };

    const handlePhoneNumberChange = (e, stateSetter) => {
        const input = e.target.value;
        const numberRegex = /^\d{0,10}$/;

        if (numberRegex.test(input)) {
            stateSetter((prevState) => ({ ...prevState, number: input }));
        }
    };
    const handleSuggestionClick = (name) => {
        console.log(name, "testnamesss")
        setState({
            ...state,
            user: `${name}`,
            // suggestion: false,
            suggestionCard: false,
        });
        setSelectedSuggestion(name);
    };
    const handleSuggestionClickteach = (name) => {
        console.log(name, "testnamesss")
        setStateTeacher({
            ...stateTeacher,
            user: `${name}`,
            // suggestion: false,
            suggestionCard: false,
        });
        setSelectedSuggestionteach(name);
    };
    const handleSuggestionClickparent = (name) => {
        console.log(name, "testnamesss")
        setparentState({
            ...parentState,
            user: name,
            // suggestion: false,
            suggestionCard: false,
        });
        setSelectedSuggestionparent(name);
    };

    const handleButtonClickTeach = () => {
        setShowAllSubjectsTeach(!showAllSubjectsTeach);
    };

    
    useEffect(() => {
        if(userAuth && addbookOpen){
            emptyState();
            const StudentUserData = async (type) => {

                if(type === "Student"){

                let Mediumdata = UserProfileData.medium.map(medium => ({
                    label: medium.variableName,
                    value: medium._id,
                }));

                console.log(UserProfileData.standard,"STDDDDD");
        
                let Standarddata = UserProfileData.standard.map(standard => ({
                    label: standard.variableName+" Std - "+standard.class+"",
                    value: standard._id,
                    variableN: standard.variableName
                }));

                let Subjectdata = UserProfileData.subjects.map(subss => ({
                    label: subss.parentVaribaleId.variableName,
                    value: subss.parentVaribaleId._id,
                }));
        
                var medium_data = UserProfileData.medium.map((item) => { return item._id })
        
                    let medbod = {
                        "variableIds": medium_data
                    }
                    let Medres = await getstandardbyMID(medbod);
        
                    if (Medres?.success === true) {
                        console.log(Medres, "aftersuccess")
                        let data = Medres.data.filter(ss => ss.variableName === UserProfileData.standard[0]?.variableName).map(std => ({
                                label: std.variableName+" Std - "+std.class+"",
                                value: std._id,
                                variableN: std.variableName
                        }))
                        console.log(data,"wwwwwwwwwwwwwwwwww");
                        setstdarray(data);

                        const Stdvalue = data.map(standard => standard.value);
                        let bod = {
                            "variableIds": Stdvalue
                        }
                        let res = await getstandardbyMID(bod, "student");
                        if (res?.success === true) {
                            console.log(res.data, "subjectdaaaaa")
                            let subssdata = res.data.map(subs => ({
                                label: subs.variableName,
                                value: subs._id,
                            }));
                            setsubarray(subssdata);
                            setSelectedSubjects(Subjectdata)
                        } else {
                            setsubarray([]);
                            setSelectedSubjects([])
                        }
                        
                        
                    }
        

                setState({ ...state, MediumInstruction: Mediumdata, Standard: Standarddata });
                setStudentAddbookModal(true)

                }

                if(type === "Teacher"){

                    let Mediumdata = UserProfileData.medium.map(medium => ({
                        label: medium.variableName,
                        value: medium._id,
                    }));
    
                    // console.log(UserProfileData.standard,"STDDDDD");
                    // console.log(Mediumdata,"STDDDDD");
            
                    let Standarddata = UserProfileData.standard.map(standard => ({
                        label: standard.variableName+" Std - "+UserProfileData.medium.filter(cc => cc._id === standard.parentVaribaleId)[0].variableName+"",
                        value: standard._id,
                    }));
    
                    let Subjectdata = UserProfileData.subjects.map(subss => ({
                        label: subss.parentVaribaleId.variableName,
                        value: subss.parentVaribaleId._id,
                    }));

                    const allSelected = UserProfileData.subjects.flat().reduce((acc, x) => {
                        acc[x.parentVaribaleId._id] = true;
                        return acc;
                    }, {});
                    setSelectedSubjectsTeach(allSelected);
                    setSelectedSubjects(allSelected)
                    console.log(allSelected,"STDDDDD");
            
                    var medium_data = UserProfileData.medium.map((item) => { return item._id })
            
                        let medbod = {
                            "variableIds": medium_data
                        }
                        let Medres = await getstandardbyMID(medbod);
            
                        if (Medres?.success === true) {
                            console.log(Medres, "aftersuccess")
                            let data = Medres.data.map(std => ({
                                    label: std.variableName,
                                    value: std._id,
                            }))
                            console.log(data,"wwwwwwwwwwwwwwwwww");
                            setstdteacherarray(data);
    
                            const Stdvalue = Standarddata.map(standard => standard.value);
                            let bod = {
                                "variableIds": Stdvalue
                            }
                            let res = await getstandardbyMID(bod, "teacher");
                            if (res?.success === true) {
                                console.log(res.data, "subjectdaaaaa")
                                setsubteacherarray(res.data);
                            } else {
                                setsubteacherarray([]);
                            }
                            
                            
                        }
            
    
                    setStateTeacher({ ...stateTeacher, MediumInstruction: Mediumdata, Standard: Standarddata });
                    setTeacherAddbookModal(true)
    
                    }
                    setaddbookOpen(false)
            };

            StudentUserData(UserProfileType?.usertype)
        }
    },[addbookOpen]);
    useEffect(() => {
        var lastScrollTop;
        let navbar = document.getElementById("navbar");
    
        const handleScroll = () => {
            if(navbar){
          var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          if (scrollTop > lastScrollTop) {
            navbar.style.top = "-80px";
          } else {
            navbar.style.top = "0";
          }
          lastScrollTop = scrollTop;
        }
        };
    
        // Check screen width before adding the scroll event listener
        const addScrollListener = () => {
          if (window.innerWidth > 600) {
            window.addEventListener("scroll", handleScroll);
          }
        };
    
        addScrollListener(); // Add the listener initially
    
        // Cleanup: Remove the scroll event listener when component unmounts
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

      const isNumber = (value) => {
        console.log(value,"yyyyyyyyyyy")
        if(typeof value === "string"){
            console.log(!isNaN(value),"yyyyyyyyyyy")
            return !isNaN(value)
        }
      }

      const uservalidateFunc = async (mobileNo) => { 
        let bod = {
            mobileNo: mobileNo,
            role: roleName
        }

        let res = await getUserListFromUsers(bod);

        if(res.success){
            if(res?.data?.otherUser?.length > 1){
                setUserValidate(true)
                setUserValidateList(res.data)
            } else {
                setUserValidate(false)
                setUserValidateList({})
            }
        } else {
            setUserValidate(false)
            setUserValidateList({})
        }
        
    }; 
    

    return (
        <Fragment>
            { !userAuth && <div>
                <div id="navbar" className="backGroung_sky fixed w-100">
                    <MediaQuery minWidth={700}>
                        <div class="container py-3">
                            <div class="row align-items-center">
                                <div class="col-md-3">
                                    <div>
                                        <img src={emblem} style={{ width: "88%"}} />
                                    </div>
                                </div>

                                <div class="col-md-9">
                                    <div className="d-flex align-items-center justify-content-end gap-5">
                                        <Link
                                            to="/"
                                            className={`header_content_font textDecvor ${activeTab === "Home" ? "fw-bold" : ""
                                                }`}
                                            onClick={() => handleTabClick("Home")}
                                        >
                                            Home
                                        </Link>
                                        <Link
                                            to="/inventor"
                                            className={`header_content_font textDecvor ${activeTab === "Inventor" ? "fw-bold" : ""
                                                }`}
                                            onClick={() => handleTabClick("Inventor")}
                                        >
                                            Inventor
                                        </Link>
                                        <Link
                                            to="/katbook"
                                            className={`header_content_font textDecvor ${activeTab === "KATBOOK" ? "fw-bold" : ""
                                                }`}
                                            onClick={() => handleTabClick("KATBOOK")}
                                        >
                                            KATBOOK
                                        </Link>
                                        <div
                                            className={`header_content_font ${activeTab === "NCERT" ? "fw-bold" : ""
                                                }`}
                                            onClick={() => {
                                                NcertClick()
                                                // setNcertModal(!ncertModal);
                                            }}
                                        >
                                            NCERT
                                        </div>
                                        <div
                                            className={`header_content_font ${activeTab === "Routes 2 Roots" ? "fw-bold" : ""
                                                }`}
                                            onClick={() => {
                                                // setRouteModal(!routeModal);
                                                RoutesClick()
                                            }}
                                        >
                                            Routes 2 Roots
                                        </div>
                                        <div
                                            className={`header_content_font ${activeTab === "DIKSHA" ? "fw-bold" : ""
                                                }`}
                                            onClick={() => window.open(`https://diksha.gov.in/`, "_blank")}
                                        >
                                            DIKSHA
                                        </div>
                                        
                                        <div
                                            className="header_content_font_register px-3 py-2"
                                            onClick={() => {
                                                setRegisterModal(!registerModal);
                                            }}
                                        >
                                            Register
                                        </div>
                                        <div
                                            className={`header_content_font ${activeTab === "Login" ? "fw-bold" : ""
                                                }`}
                                            onClick={() => {
                                                setLoginRoleModal(true);
                                            }}
                                        >
                                            Login
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={700}>
                        <div class="container py-3 d-flex justify-content-between w-100">
                            <div>
                                <img src={emblem} className="headerEmbelem" />
                            </div>
                            <div>
                                <div className="d-flex gap-3 ps-3 align-items-center    ">
                                    <div
                                        className="header_content_font_register px-2 "
                                        onClick={() => {
                                            setRegisterModal(!registerModal);
                                        }}
                                    >
                                        Register
                                    </div>
                                    <div
                                        className={`header_content_font login-mob ${activeTab === "Login" ? "fw-bold" : ""
                                            }`}
                                        onClick={() => {
                                            setLoginRoleModal(true);
                                        }}
                                    >
                                        Login
                                    </div>

                                    <div className="relative">
                                        {menuOpen ? (
                                            <div
                                                onClick={() => {
                                                    setMenuOpen(false);
                                                    setMenuOpenContent(false);
                                                }}
                                            >
                                                <IoMdClose color="#237E85" size={23} />
                                            </div>
                                        ) : (
                                            <GiHamburgerMenu
                                                color="#237E85"
                                                size={23}
                                                onClick={() => {
                                                    setMenuOpen(true);
                                                    setMenuOpenContent(true);
                                                }}
                                            />
                                        )}

                                        {menuOpenContent && (
                                            <div className="absolute">
                                                <div className="contact-dropdown-width_writeMail">
                                                    <div className="d-flex flex-column">
                                                        <Link
                                                            to="/"
                                                            className="textDecvor dropdown-name-font-head border-bottom py-2 px-3"
                                                            onClick={() => {
                                                                setMenuOpen(false);
                                                                setMenuOpenContent(false);
                                                            }}
                                                        >
                                                            Home
                                                        </Link>
                                                        <Link
                                                            to="/inventor"
                                                            className="textDecvor dropdown-name-font border-bottom py-2 px-3"
                                                            onClick={() => {
                                                                setMenuOpen(false);
                                                                setMenuOpenContent(false);
                                                            }}
                                                        >
                                                            Inventor
                                                        </Link>
                                                        <Link
                                                            to="/katbook"
                                                            className="textDecvor dropdown-name-font border-bottom py-2 px-3"
                                                            onClick={() => {
                                                                setMenuOpen(false);
                                                                setMenuOpenContent(false);
                                                            }}
                                                        >
                                                            KATBOOK
                                                        </Link>
                                                        <div className="dropdown-name-font border-bottom py-2 px-3"
                                                        onClick={() => {setMenuOpen(false);setMenuOpenContent(false);NcertClick()}}
                                                        >
                                                            NCERT
                                                        </div>
                                                        <div className="dropdown-name-font border-bottom py-2 px-3"
                                                        onClick={() => {setMenuOpen(false);setMenuOpenContent(false);RoutesClick()}}
                                                        >
                                                            Routes 2 Roots
                                                        </div>
                                                        <div className="dropdown-name-font border-bottom py-2 px-3"
                                                        onClick={() => window.open(`https://diksha.gov.in/`, "_blank")}
                                                        >
                                                            DIKSHA
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MediaQuery>
                </div>
            </div>
            }

            <ModalComponent
                size={"lg"}
                show={registerModal}
                onClick={() => {
                    setRegisterModal(false);
                }}

                Content={
                    //   <div>
                    //   <div>
                    <div className="row mb-2">
                      <div className="Select_Register">
                        <div className="col-md-4 mb-2">
                            <div className="d-flex flex-column align-items-center gap-1">
                                <div
                                    className=""
                                    onClick={() => {
                                        setRegisterModal(false);
                                        setStudentRegisterModal(true);
                                    }}
                                >
                                    <img src={student} className="registerImgborder" />
                                </div>
                                <div className="fontregistModal">Students</div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-2">
                            <div className="d-flex flex-column align-items-center gap-1">
                                <div className="" 
                                    onClick={() => {
                                        setRegisterModal(false);
                                        setParentRegisterModal(true);
                                    }}>
                                    <img src={Parents} className="registerImgborder" />
                                </div>
                                <div className="fontregistModal">Parents</div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="d-flex flex-column align-items-center gap-1">
                                <div className=""
                                    onClick={() => {
                                        setRegisterModal(false);
                                        setTeacherregisterModal(true);
                                    }}>
                                    <img src={Teachers} className="registerImgborder" priority="high" />
                                </div>
                                <div className="fontregistModal">Teachers</div>
                            </div>
                        </div>

                        
                    </div>
                       </div>
                }
            />

            <ModalComponent
                size={"lg"}
                show={LoginRoleModal}
                onClick={() => {
                    setLoginRoleModal(false);
                }}
                Content={
                    //   <div>
                    //   <div>
                    <div className="row mb-2">
                      <div className="Select_Register">
                        <div className="col-md-4 mb-2">
                            <div className="d-flex flex-column align-items-center gap-1">
                                <div
                                    className=""
                                    onClick={() => {
                                        setLoginRoleModal(false);
                                        setLoginModal(true);
                                        setroleName("Student")
                                    }}
                                >
                                    <img src={student} className="registerImgborder" />
                                </div>
                                <div className="fontregistModal">Students</div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-2">
                            <div className="d-flex flex-column align-items-center gap-1">
                                <div className="" 
                                    onClick={() => {
                                        setLoginRoleModal(false);
                                        setLoginModal(true);
                                        setroleName("Parent")
                                    }}>
                                    <img src={Parents} className="registerImgborder" />
                                </div>
                                <div className="fontregistModal">Parents</div>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="d-flex flex-column align-items-center gap-1">
                                <div className=""
                                    onClick={() => {
                                        setLoginRoleModal(false);
                                        setLoginModal(true);
                                        setroleName("Teacher")
                                    }}>
                                    <img src={Teachers} className="registerImgborder" priority="high" />
                                </div>
                                <div className="fontregistModal">Teachers</div>
                            </div>
                        </div>

                        
                    </div>
                       </div>
                }
            />

            {/* Student Registration Modal */}
            <ModalComponent
                // size={"xl"}
                dialogClassName={"register_modal"}
                show={studentregisterModal}
                Title={"Student Registration"}
                onClick={() => {
                    setStudentRegisterModal(false);
                    emptyState();
                }}
                className={"Register_insidescroll"}
                Content={
                    <div>
                        <div className="container">
                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"First Name"}
                                        placeholder={"Enter First Name"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        value={state.firstName}
                                        onChange={(e) => {
                                            const userInput = e.target.value
                                            const concatenatedValue = userInput.trim()+ state.lastName.trim();
                                            const finalValue = concatenatedValue.replace(/\s+/g, ''); // Remove spaces
                                            setState({
                                                ...state,
                                                firstName: userInput,
                                                user: `${finalValue}`,
                                                suggestion: false,
                                                suggestionCard: false,
                                            });
                                            setcheckusernotify("")
                                            setsuccesscheckusernotify("")
                                        }}
                                    // isDisabled={studentregisterModal ? false : true}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Input
                                        label={"Last Name"}
                                        placeholder={"Enter Last Name"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        value={state.lastName}
                                        onChange={(e) => {
                                            const userInput = e.target.value;
                                            const concatenatedValue = state.firstName.trim() + userInput.trim();
                                            const finalValue = concatenatedValue.replace(/\s+/g, ''); // Remove spaces
                                            setState({
                                                ...state,
                                                lastName: userInput,
                                                user: `${finalValue}`,
                                                suggestion: false,
                                                suggestionCard: false,
                                            });
                                            setcheckusernotify("")
                                            setsuccesscheckusernotify("")
                                        }}
                                        isDisabled={state.firstName ? false : true}
                                    />
                                </div>
                            </div>

                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"Date of Birth"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isDate={true}
                                        value={state.birth}
                                        maxDate={moment().subtract(4, "years").format("YYYY-MM-DD")}
                                        onChange={(e) => {
                                            setState({ ...state, birth: e.target.value });
                                        }}
                                        isDisabled={state.lastName ? false : true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label={"Gender"}
                                        Selectplaceholder={"Select Gender"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2 dropdown_font_css"}
                                        isDropdown
                                        value={state.gender}
                                        options={options[1]}
                                        onChange={(e) => {
                                            setState({ ...state, gender: e });
                                        }}
                                        isDisabled={state.birth ? false : true}
                                    />
                                </div>
                            </div>

                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"Mobile No"}
                                        placeholder={"Enter Mobile No"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        value={state.number}
                                        onChange={(e) => handlePhoneNumberChange(e, setState)}
                                        isDisabled={state.gender?.value ? false : true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label={"School Name"}
                                        placeholder={"Enter School Name"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        value={state.schoolName}
                                        onChange={(e) => {
                                            setState({ ...state, schoolName: e.target.value });
                                        }}
                                        isDisabled={state.number.length !== 10}
                                    />
                                </div>
                            </div>

                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"Medium of Instruction"}
                                        Selectplaceholder={"Select Medium of Instruction"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        // options={options[2]}
                                        isDropdown
                                        isMulti
                                        value={state.MediumInstruction}
                                        // onChange={(e) => {
                                        //     setState({ ...state, MediumInstruction: e });
                                        //     getstandard(e);
                                        // }}
                                        onChange={handleMediumChange}
                                        options={mediumarray}
                                        isDisabled={state.schoolName ? false : true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label={"Standard"}
                                        Selectplaceholder={"Select Standard"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isDropdown
                                        isMulti
                                        // options={options}
                                        value={state.Standard}
                                        onChange={(e) => {
                                            console.log(state.Standard.length > 0 && state.Standard.filter((x) => x.variableN === e[e.length -1]?.variableN).length,"Student Data")
                                            console.log(e,"Student Data");
                                            console.log(state.Standard,"Student Data");
                                            if(e.length === 0){
                                            setState({ ...state, Standard: "" });
                                            } else if(state.Standard.length === 0){
                                            console.log(state.Standard,"Student Data");
                                            console.log(e,"Student Data");
                                            setState({ ...state, Standard: e });
                                            setsubarray([]);
                                            setSelectedSubjects([]);
                                            getsubjectlist(e);
                                            } else if(state.Standard.length !== 0 && state.Standard.filter((x) => x.variableN === e[e.length -1]?.variableN).length !== 0){
                                                console.log(state.Standard,"Student Data");
                                            console.log(e,"Student Data");
                                            setState({ ...state, Standard: e });
                                            setsubarray([]);
                                            setSelectedSubjects([]);
                                            getsubjectlist(e);
                                            } else {
                                                toast.error("Student can select only one standard")
                                            }
                                        }}
                                        options={stdarray}
                                        isDisabled={state.MediumInstruction.length > 0 ? false : true}
                                    />
                                </div>
                                {/* <div className="col-md-6 d-flex flex-row gap-5 w-100">
                                    
                                    <Select
                                        label="Select Subject"
                                        placeholder="Select Subject"
                                        isMulti
                                        value={state.Subject}
                                        onChange={handleStudentSubjectChange}
                                        options={subarray}
                                        // setState({ ...state, Standard: e.target.value });
                                    />
                                     
                                </div> */}
                            </div>

                            {state.Standard.length > 0 && <div className="col-md-6 d-flex flex-row pt-2 gap-5 w-100">
                                <div className="d-flex flex-column w-100 gap-1">
                                    <label className={` Select_label `}>
                                        Subject<code>*</code>
                                    </label>

                                    <div className="selectallsubjectBack">
                                        <div className='d-flex flex-row flex-wrap align-items-center row-gap-2 column-gap-5'>
                                            {subarray.map((x, i) => (
                                                <div
                                                    key={i}
                                                    className={`d-flex gap-2 ${i >= 6 && !showAllSubjects ? "d-none" : ""
                                                        }`}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedSubjects.some(subject => subject.label === x.label && subject.value === x.value)}
                                                        onChange={() => handleCheckboxChange(x)}
                                                    />
                                                    <label className="subject_style">{x.label}</label>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="d-flex justify-content-end border-bottom pb-1">
                                            {subarray.length > 6 && (
                                                <div
                                                    onClick={handleButtonClick}
                                                    className="cursor subject_style pt-2"
                                                >
                                                    {showAllSubjects ? "View Less  ▲" : "View More  ▼ "}
                                                </div>
                                            )}
                                        </div>
                                        

                                        <div className="d-flex justify-content-between align-items-center pt-1 w-100">
                                            <div className="Select_label d-flex flex-wrap w-75">
                                                Selected Subject :   {selectedSubjects.map(subject => subject.label).join(', ')}
                                            </div>
                                            <div className="d-flex gap-2 pt-1 button_width">
                                                <div
                                                    type="button"
                                                    className="select_button "
                                                    onClick={handleSelectAll}
                                                >
                                                    Select All
                                                </div>
                                                <div
                                                    type="button"
                                                    className="clear_button"
                                                    onClick={handleClearAll}
                                                >
                                                    Clear All
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {/* { console.log(Object.keys(subarray),"aaaaaaaaaaaa") } */}

                            {/* {Object.keys(subarray).length > 0 && 
                                <div className="col-md-6 d-flex flex-row gap-5 w-100">
                                    <div className="d-flex flex-column w-100 gap-1">
                                        <label className={` Select_label `}>
                                            Subject<code>*</code>
                                        </label>
                                        <div className="position-relative d-flex flex-row"></div>


                                        <div className="selectallsubjectBack">
                                            {Object.keys(subarray).map(className => (
                                                <div key={className}>
                                                    <div className="pb-1">
                                                        <div className="Select_label" > Standard -  {className}</div>
                                                    </div>
                                                    <div className="d-flex flex-row flex-wrap align-items-center row-gap-2 column-gap-4">
                                                        {subarray[className].map((x, i) => (
                                                            <div
                                                                key={i}
                                                                className={`d-flex gap-2 ${i >= 6 && !showAllSubjectsTeach ? "d-none" : ""
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedSubjectsTeach[x._id]}
                                                                    onChange={() => handleCheckboxChangeTeach(x._id)}
                                                                />
                                                                <label className="subject_style"> {x.variableName}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="d-flex justify-content-end border-bottom pb-1">
                                                {Object.values(subarray).flat().length > 6 && (
                                                    <div
                                                        onClick={handleButtonClickTeach}
                                                        className="cursor subject_style pt-2"
                                                    >
                                                        {showAllSubjectsTeach
                                                            ? "View Less ▲"
                                                            : "View More ▼ "}
                                                    </div>
                                                )}
                                            </div>


                                            <div className="d-flex justify-content-end gap-2 pt-1">
                                                <div
                                                    type="button"
                                                    className="select_button"
                                                    onClick={handleSelectAllTeach}
                                                >
                                                    Select All
                                                </div>
                                                <div
                                                    type="button"
                                                    className="clear_button"
                                                    onClick={handleClearAllTeach}
                                                >
                                                    Clear All
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                } */}


                            <hr style={{ border: "2px dashed black" }} />
                                <div className="col-md-12 d-flex flex-column gap-2 justify-content-center align-items-center modal-dropdown-size">
                                    <div className="col-md-6 Register_input gap-2 w-100">
                                        <div className="d-flex flex-column w-100 relative">
                                            {state.suggestionCard && (
                                                <div className="suggestionAbsolute">
                                                    {usernamesuggestion.map((name, index) => (
                                                        <div
                                                            key={index}
                                                            className={`d-flex flex-column ${selectedSuggestion === name ? 'selected' : ''}`}
                                                            onClick={() => handleSuggestionClick(name)}
                                                        >
                                                            <div className="textDecvor sugesteGetFont border-bottom py-1 px-2">
                                                                {/* SAkashpandurangan07 */}
                                                                {name}
                                                            </div>
                                                        </div>

                                                    ))}

                                                </div>
                                            )}
                                            <Input
                                                label={"User Id"}
                                                placeholder={"Set User Id"}
                                                labelClassName={"Select_label"}
                                                className={"input-size p-2"}
                                                value={state.user ? `${state.user}` : ''}
                                                showRightIcon={
                                                    <div
                                                        onClick={() => {
                                                            if(state.user && state.lastName && state.firstName && checksuccessusernotify === ""){
                                                                checkuseridfun("student");
                                                            }
                                                            
                                                        }}
                                                        style={{
                                                            cursor: state.user && state.lastName && state.firstName && checksuccessusernotify === "" ? "pointer" : "default",
                                                            color: checksuccessusernotify ? "#c1c1c1" : ""
                                                        }}
                                                        className="cursor-pointer checkFont"
                                                    >
                                                        Check
                                                    </div>
                                                }

                                                isDisabled={true}
                                            />
                                                    <div className="align-items-center w-100">
                                                        {checkusernotify && <div className="sugesstionFontred d-flex align-items-center gap-1">
                                                            <FaExclamationCircle /> {checkusernotify}
                                                        </div>
                                                        }
                                                        {checksuccessusernotify && <div className="sugesstionFontgreen d-flex align-items-center gap-1">
                                                        <IoIosCheckmarkCircle /> {checksuccessusernotify}
                                                        </div>}
                                                        {state.suggestion && <div
                                                            className="sugesstionFonblack cursor-pointer"
                                                            onClick={() => {
                                                                setState({
                                                                    ...state,
                                                                    suggestionCard: true,
                                                                });
                                                                useridsuggestionlist("student");
                                                            }}
                                                        >
                                                            Get Suggestion
                                                        </div>
                                                        }
                                                    </div>
                                              
                                        </div>
                                       <>
                                       
                                        <Input
                                            label={"Password"}
                                            placeholder={"Set Password"}
                                            labelClassName={"Select_label"}
                                            className={"input-size p-2"}
                                            isPassword
                                            value={state.pwd}
                                            onChange={(e) => {
                                                setState({ ...state, pwd: e.target.value });
                                            }}
                                            isDisabled={state.user ? false : true}
                                        />
                                        <div className="d-flex flex-column w-100 relative">
                                            <div>
                                        <Input
                                            label={"Confirm Password"}
                                            placeholder={"Set Password"}
                                            labelClassName={"Select_label"}
                                            className={"input-size p-2"}
                                            isPassword
                                            value={state.confirmPwd}
                                            // onChange={(e) => {
                                            //     setState({ ...state, confirmPwd: e.target.value });
                                            // }}
                                            onChange={handleConfirmPasswordChangeStu}
                                            isDisabled={state.pwd ? false : true}
                                        />
                                        </div>
                                        <div>
                                        {(state.pwd && state.confirmPwd && state.pwd !== state.confirmPwd) &&(
                                                <>
                                                
                                                    <div className="d-flex justify-content-between align-items-center w-100">
                                                        <div className="sugesstionFontred d-flex align-items-center gap-1">
                                                        <FaExclamationCircle /> Passwords do not match
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            </div>
                                            </div>
                                            </>
                                        <button
                                            className="btn btn-success butonRegister mt-4 mb-3"
                                            onClick={() => {

                                                if(state.number.length !== 10){
                                                    toast.error("Please enter 10 digit mobile no");
                                                } else {
                                                    setregistertype("student-registration")
                                                    setmobilenumber(state.number)
                                                    studentregisterfunc();
                                                    setSecondsPassed(0)
                                                    setTimerActive(true)
                                                    setisResend(true)
                                                }
                                            }}
                                            disabled={isLoadingreg ? isLoadingreg : checksuccessusernotify && state.confirmPwd && selectedSubjects.length > 0 && state.pwd === state.confirmPwd ? false : true}
                                        >
                                            Register
                                        </button>
                                    </div>
                                </div>

                        </div>
                    </div>
                }
            />
            {/* Student Registration Modal */}

            {/* Parents Modal */}
            <ModalComponent
                dialogClassName={"register_modal"}
                show={ParentregisterModal}
                Title={"Parents Registration"}
                className={"Register_insidescroll"}
                onClick={() => {
                    setParentRegisterModal(false);
                    emptyStateParent();
                }}
                Content={
                    <div>
                        <div className="container">
                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"First Name"}
                                        placeholder={"Enter First Name"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        value={parentState.firstName}

                                        onChange={(e) => {
                                            const userInput = e.target.value
                                            const concatenatedValue = userInput.trim() + parentState.lastName.trim();
                                            const finalValue = concatenatedValue.replace(/\s+/g, ''); 
                                            setparentState({
                                                ...parentState,
                                                firstName: userInput,
                                                user: finalValue,
                                                suggestion: false,
                                                suggestionCard: false,
                                            });
                                            setsuccesscheckusernotify("")
                                            setcheckusernotify("")
                                        }}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Input
                                        label={"Last Name"}
                                        placeholder={"Enter Last Name"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        value={parentState.lastName}

                                        onChange={(e) => {
                                            const userInput = e.target.value
                                            const concatenatedValue = parentState.firstName.trim() + userInput.trim();
                                            const finalValue = concatenatedValue.replace(/\s+/g, ''); 
                                            setparentState({
                                                ...parentState,
                                                lastName: userInput,
                                                user: finalValue,
                                                suggestion: false,
                                                suggestionCard: false,
                                            });
                                            setsuccesscheckusernotify("")
                                            setcheckusernotify("")
                                        }}
                                        isDisabled={parentState.firstName ? false : true}
                                    />
                                </div>
                            </div>

                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"Date of Birth"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isDate={true}
                                        maxDate={moment().subtract(18, "years").format("YYYY-MM-DD")}
                                        value={parentState.birth}
                                        onChange={(e) => {
                                            setparentState({ ...parentState, birth: e.target.value });
                                        }}
                                        isDisabled={parentState.lastName ? false : true}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Input
                                        label={"Mobile No"}
                                        placeholder={"Enter Mobile No"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        value={parentState.number}
                                        // onChange={(e) => {
                                        //   setparentState({
                                        //     ...parentState,
                                        //     number: e.target.value,
                                        //   });
                                        // }}
                                        onChange={(e) => handlePhoneNumberChange(e, setparentState)}
                                        isDisabled={parentState.birth ? false : true}
                                    />
                                </div>
                            </div>

                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"Select Relationship"}
                                        Selectplaceholder={"Select"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isDropdown
                                        value={parentState.relation}
                                        options={selctGaurd}
                                        onChange={(e) => {
                                            { console.log(e, "testggg") }
                                            guardOnChange(e);
                                            setparentState({
                                                ...parentState,
                                                relation: e, 
                                                gender:"", // Use e.value instead of e.target.value
                                                StudentId: "",
                                                suggestion: false,
                                                suggestionCard: false,
                                            });
                                            setsuccesscheckusernotify("")
                                            setcheckusernotify("")
                                        }}
                                        isDisabled={parentState.number.length !== 10}
                                   
                                    />
                                </div>

                                {gaurdShow ? (
                                    <div className="col-md-6">
                                        <Input
                                            label={"Gender"}
                                            Selectplaceholder={"Select Gender"}
                                            labelClassName={"Select_label"}
                                            className={"input-size p-2"}
                                            isDropdown
                                            options={options[1]}
                                            value={parentState.gender}
                                            onChange={(e) => {

                                                setparentState({
                                                    ...parentState,
                                                    gender: e,
                                                    StudentId : ""
                                                });
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className="col-md-6">
                                        <Input
                                            label={"Student Id"}
                                            placeholder={"Student Id"}
                                            labelClassName={"Select_label"}
                                            className={"input-size p-2"}
                                            value={parentState.StudentId}
                                            onChange={(e) => {
                                              
                                                setparentState({
                                                    ...parentState,
                                                    StudentId: e.target.value,
                                                });
                                                setuserdetailshow(false)
                                            }}
                                            isDisabled={parentState.relation ? false : true}
                                            showRightIcon={
                                                <div
                                                    onClick={() => {
                                                       
                                                         getuserdetailsbyId()
                                                    }}
                                                    className="cursor-pointer checkFont"
                                                >
                                                    Check
                                                </div>
                                            }

                                        />
                                      {/* <input
          type="checkbox"
          checked={parentState.checkvalue}
          onChange={handleUserCheckboxChange}
        /> */}
                                    </div>
                                )}
                            </div>

                            <div className="row pt-2 ">
                                {gaurdShow && (
                                    <div className="col-md-6">
                                        <Input
                                            label={"Student Id"}
                                            placeholder={"Student Id"}
                                            labelClassName={"Select_label"}
                                            className={"input-size p-2"}
                                            value={parentState.StudentId}
                                            onChange={(e) => {
                                              
                                                setparentState({
                                                    ...parentState,
                                                    StudentId: e.target.value,
                                                });
                                                setuserdetailshow(false)
                                              
                                            }}
                                            isDisabled={parentState.gender ? false : true}
                                            showRightIcon={
                                                <div
                                                    onClick={() => {
                                                       
                                                         getuserdetailsbyId()
                                                    }}
                                                    className="cursor-pointer checkFont"
                                                >
                                                    Check
                                                </div>
                                            }

                                        />
                                        <div></div>
                                    </div>
                                )}
                            </div>
                            {parentState.StudentId && userdetailshow && (
                                <div className="col-md-12 d-flex flex-row gap-5 w-100 mt-3">
                                    <div className="studentdetailsBack">
                                        {userdetails.map((user) => (
                                            <div>
                                                <div key={user.userId}>
                                                    <div className="d-flex justify-content-between py-2 px-3">
                                                        <div className="d-flex flex-column gap-2 justify-content-center ">
                                                            <div className="headstudentDetails">Student Name</div>
                                                            <div className="headstudentDetailsContent">
                                                                {`${user.firstName} ${user.lastName}`}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column gap-2 justify-content-center ">
                                                            <div className="headstudentDetails">School</div>
                                                            <div className="headstudentDetailsContent">
                                                                {user.schoolName}
                                                                {/* {console.log(userdetails[0].schoolName,"testcccccc")} */}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column gap-2 justify-content-center ">
                                                            <div className="headstudentDetails">Standard</div>
                                                            <div className="headstudentDetailsContent">
                                                                {user.standard[0]?.variableName}
                                                            </div>
                                                        </div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>



                                                <div className="d-flex justify-content-between py-2 px-3">
                                                    <div className="d-flex flex-column gap-2 justify-content-center ">
                                                        <div className="headstudentDetails">Subject</div>
                                                        <div className="headstudentDetailsContent">
                                                           
                                                            {/* {user.subject && user.standard[0]?.variableName && user.subject[user.standard[0]?.variableName] ? (
        user.subject[user.standard[0]?.variableName].map((subject, index) => (
            <span key={index}>{subject.parentVaribaleId.variableName}, </span>
        ))
    ) : (
        <span>No subjects available</span>

    )} */}
  

    {user.subject.map(subject => (
         <span key={subject._id}>{subject.parentVaribaleId.variableName}, </span>
       
      ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <hr style={{ border: "2px dashed black" }} />
                            <div className="col-md-12 d-flex flex-column gap-2 justify-content-center align-items-center modal-dropdown-size">
                                <div className="col-md-6 Register_input gap-2 w-100">
                                    <div className="d-flex flex-column w-100 relative">
                                        {parentState.suggestionCard && (
                                            <div className="suggestionAbsolute">
                                                {usernamesuggestion.map((name, index) => (
                                                    <div
                                                        key={index}
                                                        className={`d-flex flex-column ${selectedSuggestionparent === name ? 'selected' : ''}`}
                                                        onClick={() => {
                                                            handleSuggestionClickparent(name)
                                                            setsuccesscheckusernotify("")
                                                            setcheckusernotify("")
                                                        }}
                                                    >
                                                        <div className="textDecvor sugesteGetFont border-bottom py-1 px-2">
                                                            {/* SAkashpandurangan07 */}
                                                            {name}
                                                        </div>
                                                    </div>

                                                ))}

                                            </div>
                                        )}
                                        <Input
                                            label={"User Id"}
                                            placeholder={"Set User Id"}
                                            labelClassName={"Select_label"}
                                            className={"input-size p-2"}
                                            value={
                                                parentState.relation.value === "Father"
                                                    ? `F${parentState.user}`
                                                    : parentState.relation.value === "Mother"
                                                        ? `M${parentState.user}`
                                                        : parentState.relation.value === "Guardian"
                                                            ? `G${parentState.user}`
                                                            : `${parentState.user}`
                                            }
                                            showRightIcon={
                                                <div
                                                    onClick={() => {
                                                        if(parentState.relation.value && parentState.firstName && parentState.lastName && checksuccessusernotify === ""){
                                                            checkuseridfun("parent");
                                                        }
                                                     
                                                    }}
                                                    style={{
                                                            cursor: parentState.relation.value && parentState.user && parentState.lastName && parentState.firstName && checksuccessusernotify === "" ? "pointer" : "default",
                                                            color: checksuccessusernotify ? "#c1c1c1" : ""
                                                        }}
                                                    className={parentState.relation.value && parentState.firstName && parentState.lastName ? `cursor-pointer checkFont` : `checkFont`}
                                                >
                                                    Check
                                                </div>
                                            }
                                            isDisabled={true}
                                        />

                                        <div className="align-items-center w-100">
                                            {checkusernotify && <div className="sugesstionFontred d-flex align-items-center gap-1">
                                                <FaExclamationCircle /> {checkusernotify}
                                            </div>
                                            }
                                            {checksuccessusernotify && <div className="sugesstionFontgreen d-flex align-items-center gap-1">
                                            <IoIosCheckmarkCircle /> {checksuccessusernotify}
                                            </div>}
                                            {parentState.suggestion && <div
                                                className="sugesstionFonblack cursor-pointer"
                                                onClick={() => {
                                                    setparentState({
                                                        ...parentState,
                                                        suggestionCard: true,
                                                    });
                                                    useridsuggestionlist("parent");
                                                }}
                                            >
                                                Get Suggestion
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <Input 
                                        label={"Password"}
                                        placeholder={"Set Password"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isPassword
                                        value={parentState.pwd}
                                        onChange={(e) => {
                                            setparentState({ ...parentState, pwd: e.target.value });
                                        }}
                                        isDisabled={parentState.user ? false : true}
                                    />
                                    <div className="d-flex flex-column w-100 relative">
                                            <div>
                                    <Input
                                        label={"Confirm Password"}
                                        placeholder={"Set Password"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isPassword
                                        value={parentState.confirmPwd}
                                        // onChange={(e) => {
                                        //     setparentState({
                                        //         ...parentState,
                                        //         confirmPwd: e.target.value,
                                        //     });
                                        // }}
                                        onChange={handleConfirmPasswordChangePar}
                                        isDisabled={parentState.pwd ? false : true}
                                    />
                                    </div>
                                    <div>
                                     {(parentState.pwd && parentState.confirmPwd && parentState.pwd !== parentState.confirmPwd ) &&(
                                                <>
                                                    <div className="d-flex justify-content-between align-items-center w-100">
                                                        <div className="sugesstionFontred d-flex align-items-center gap-1">
                                                        <FaExclamationCircle /> Passwords do not match
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            </div>
                                            </div>
                                    <button
                                        className="btn btn-success butonRegister margin_mobiles mt-4 mb-3"
                                        onClick={() => {

                                            if(parentState.number.length !== 10){
                                                    toast.error("Please enter 10 digit mobile no");
                                                } else {
                                                    setregistertype("parent-registration")
                                                    setmobilenumber(parentState.number)
                                                    parentregisterfunc();
                                                    setSecondsPassed(0)
                                                    setTimerActive(true)
                                                    setisResend(true)
                                                }
                                        }}
                                        disabled={isLoadingreg ? isLoadingreg : checksuccessusernotify && parentState.confirmPwd && parentState.pwd === parentState.confirmPwd && parentState.StudentId && userdetailshow ? false : true}
                                    >
                                        Register
                                    </button>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                }
            />
            {/* Parents Modal */}

            {/* Teacher Modal */}
            <ModalComponent
                dialogClassName={"register_modal"}
                show={TeacherregisterModal}
                Title={"Teacher Registration"}
                className={"Register_insidescroll"}
                onClick={() => {
                    setTeacherregisterModal(false);
                    emptyStateTeacher();
                }}
                Content={
                    <div>
                        <div className="container">
                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"First Name"}
                                        placeholder={"Enter First Name"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        value={stateTeacher.firstName}
                                        onChange={(e) => {
                                            const userInput = e.target.value
                                            const concatenatedValue = userInput.trim() + stateTeacher.lastName.trim();
                                            const finalValue = concatenatedValue.replace(/\s+/g, ''); 
                                            setStateTeacher({
                                                ...stateTeacher,
                                                firstName: userInput,
                                                user: `${finalValue}`,
                                                suggestion: false,
                                                suggestionCard: false,
                                            });
                                            setsuccesscheckusernotify("")
                                            setcheckusernotify("")
                                        }}
                                    // isDisabled={TeacherregisterModal ? false : true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label={"Last Name"}
                                        placeholder={"Enter Last Name"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        value={stateTeacher.lastName}
                                        onChange={(e) => {
                                            const userInput = e.target.value
                                            const concatenatedValue = stateTeacher.firstName.trim() + userInput.trim();
                                            const finalValue = concatenatedValue.replace(/\s+/g, ''); 
                                            setStateTeacher({
                                                ...stateTeacher,
                                                lastName: userInput,
                                                user: `${finalValue}`,
                                                suggestion: false,
                                                suggestionCard: false,
                                            });
                                            setsuccesscheckusernotify("")
                                            setcheckusernotify("")
                                        }}
                                        isDisabled={stateTeacher.firstName ? false : true}
                                    />
                                </div>
                            </div>

                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"Date of Birth"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isDate={true}
                                        maxDate={moment().subtract(18, "years").format("YYYY-MM-DD")}
                                        value={stateTeacher.birth}
                                        onChange={(e) => {
                                            setStateTeacher({
                                                ...stateTeacher,
                                                birth: e.target.value,
                                            });
                                        }}
                                        isDisabled={stateTeacher.lastName ? false : true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label={"Gender"}
                                        Selectplaceholder={"Select Gender"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isDropdown
                                        options={options[1]}
                                        value={stateTeacher.gender}
                                        onChange={(e) => {
                                            setStateTeacher({
                                                ...stateTeacher,
                                                gender: e,
                                            });
                                        }}
                                        isDisabled={stateTeacher.birth ? false : true}
                                    />
                                </div>
                            </div>
                            
                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"Mobile No"}
                                        placeholder={"Enter Mobile No"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        value={stateTeacher.number}
                                        onChange={(e) =>
                                            handlePhoneNumberChange(e, setStateTeacher)
                                        }
                                        isDisabled={stateTeacher.gender ? false : true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label={"School Name"}
                                        placeholder={"Enter School Name"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        value={stateTeacher.schoolName}
                                        onChange={(e) => {
                                            setStateTeacher({
                                                ...stateTeacher,
                                                schoolName: e.target.value,
                                            });
                                        }}
                                        
                                        isDisabled={stateTeacher.number.length !== 10}
                                    />
                                </div>
                            </div>

                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"Medium of Instruction"}
                                        Selectplaceholder={"Select Medium of Instruction"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isDropdown
                                        isMulti
                                        // options={options[2]}
                                        value={stateTeacher.MediumInstruction}
                                        // onChange={(e) => {
                                        //     setStateTeacher({ ...stateTeacher, MediumInstruction: e, Standard: [] });
                                        //     getstandard(e);
                                        // }}
                                        
                                        onChange={handleTeaMediumChange}
                                        options={mediumarray}
                                        isDisabled={stateTeacher.schoolName ? false : true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label={"Standard"}
                                        Selectplaceholder={"Select Standard"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isDropdown
                                        isMulti
                                        value={stateTeacher.Standard}
                                        // onChange={(e) => {
                                        //     setStateTeacher({ ...stateTeacher, Standard: e });
                                        //     getsubjectlist(e, "teacher");
                                        // }}
                                        onChange={handleTeacherstdChange}
                                        options={stdteacherarray}
                                        isDisabled={stateTeacher.MediumInstruction.length > 0 ? false : true}
                                    />
                                </div>
                            </div>
                                {/* <div className="col-md-6 d-flex flex-row gap-5 w-100">
                                   
                                    <Select
                                        label="Select Subject"
                                        placeholder="Select Subject"
                                        isMulti
                                        value={stateTeacher.Subject}
                                        onChange={handleSubjectChange}
                                        options={subarray}
                                    />
                                      
                                </div> */}
                                {stateTeacher.Standard.length > 0 && Object.keys(subteacherarray).length > 0 && 
                                <div className="col-md-6 d-flex flex-row gap-5 w-100">
                                    <div className="d-flex flex-column w-100 gap-1">
                                        <label className={` Select_label `}>
                                            Subject<code>*</code>
                                        </label>
                                        <div className="position-relative d-flex flex-row"></div>


                                        <div className="selectallsubjectBack">
                                            {Object.keys(subteacherarray).map(className => (
                                                <div key={className}>
                                                    <div className="pb-1">
                                                        <div className="Select_label" > Standard -  {className}</div>
                                                    </div>
                                                    <div className="d-flex flex-row flex-wrap align-items-center row-gap-2 column-gap-4">
                                                        {subteacherarray[className].map((x, i) => (
                                                            <div
                                                                key={i}
                                                                className={`d-flex gap-2 ${i >= 6 && !showAllSubjectsTeach ? "d-none" : ""
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedSubjectsTeach[x._id]}
                                                                    onChange={() => handleCheckboxChangeTeach(x._id)}
                                                                />
                                                                <label className="subject_style"> {x.variableName}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                            {/* <div className="d-flex justify-content-end border-bottom pb-1">
                                                {Object.values(subteacherarray).flat().length > 6 && (
                                                    <div
                                                        onClick={handleButtonClickTeach}
                                                        className="cursor subject_style pt-2"
                                                    >
                                                        {showAllSubjectsTeach
                                                            ? "View Less ▲"
                                                            : "View More ▼ "}
                                                    </div>
                                                )}
                                            </div> */}


                                            <div className="d-flex justify-content-end gap-2 pt-1">
                                                <div
                                                    type="button"
                                                    className="select_button"
                                                    onClick={handleSelectAllTeach}
                                                >
                                                    Select All
                                                </div>
                                                <div
                                                    type="button"
                                                    className="clear_button"
                                                    onClick={handleClearAllTeach}
                                                >
                                                    Clear All
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            

                            <hr style={{ border: "2px dashed black" }} />

                            <div className="col-md-12 d-flex flex-column gap-2 justify-content-center align-items-center modal-dropdown-size">
                                <div className="col-md-6 Register_input gap-2 w-100">
                                    <div className="d-flex flex-column w-100 relative">
                                        {stateTeacher.suggestionCard && (
                                            <div className="suggestionAbsolute">
                                                {usernamesuggestion.map((name, index) => (
                                                    <div
                                                        key={index}
                                                        className={`d-flex flex-column ${selectedSuggestionteach === name ? 'selected' : ''}`}
                                                        onClick={() => handleSuggestionClickteach(name)}
                                                    >
                                                        <div className="textDecvor sugesteGetFont border-bottom py-1 px-2">
                                                            {/* SAkashpandurangan07 */}
                                                            {name}
                                                        </div>
                                                    </div>

                                                ))}
                                            </div>
                                        )}
                                        <Input
                                            label={"User Id"}
                                            placeholder={"Set User Id"}
                                            labelClassName={"Select_label"}
                                            className={"input-size p-2"}
                                            value={stateTeacher.user ? `${stateTeacher.user}` : ''}
                                            showRightIcon={
                                                <div
                                                    onClick={() => {
                                                        if(stateTeacher.user && stateTeacher.lastName && stateTeacher.firstName && checksuccessusernotify === ""){
                                                        checkuseridfun();
                                                        }
                                                    }}
                                                    style={{
                                                            cursor: stateTeacher.user && stateTeacher.lastName && stateTeacher.firstName && checksuccessusernotify === "" ? "pointer" : "default",
                                                            color: checksuccessusernotify ? "#c1c1c1" : ""
                                                        }}
                                                    className="cursor-pointer checkFont"
                                                >
                                                    Check
                                                </div>
                                            }

                                            isDisabled={true}
                                        />
                                        <div className="align-items-center w-100">
                                            {checkusernotify && <div className="sugesstionFontred d-flex align-items-center gap-1">
                                                <FaExclamationCircle /> {checkusernotify}
                                            </div>
                                            }
                                            {checksuccessusernotify && <div className="sugesstionFontgreen d-flex align-items-center gap-1">
                                            <IoIosCheckmarkCircle /> {checksuccessusernotify}
                                            </div>}
                                            {stateTeacher.suggestion && <div
                                                className="sugesstionFonblack cursor-pointer"
                                                onClick={() => {
                                                    setStateTeacher({
                                                        ...stateTeacher,
                                                        suggestionCard: true,
                                                    });
                                                    useridsuggestionlist("teacher");
                                                }}
                                            >
                                                Get Suggestion
                                            </div>
                                            }
                                        </div>

                                    </div>
                                 
                                   
                                   
                                    <Input
                                        label={"Password"}
                                        placeholder={"Set Password"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isPassword
                                        value={stateTeacher.pwd}
                                        onChange={(e) => {
                                            setStateTeacher({ ...stateTeacher, pwd: e.target.value });
                                        }}
                                        isDisabled={stateTeacher.user ? false : true}
                                    />
                               <div className="d-flex flex-column w-100 relative">
                                            <div>    
                                    <Input
                                        label={"Confirm Password"}
                                        placeholder={"Set Password"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isPassword
                                        value={stateTeacher.confirmPwd}
                                        // onChange={(e) => {
                                        //     setStateTeacher({
                                        //         ...stateTeacher,
                                        //         confirmPwd: e.target.value,
                                        //     });
                                        // }}
                                        onChange={handleConfirmPasswordChangeTec}
                                        isDisabled={stateTeacher.pwd ? false : true}
                                    />
                                   </div>  
                                   <div>
                             {(stateTeacher.pwd && stateTeacher.confirmPwd && stateTeacher.pwd !== stateTeacher.confirmPwd ) &&(
                                                <>
                                                  
                                                    <div className="d-flex justify-content-between align-items-center w-100">
                                                        <div className="sugesstionFontred d-flex align-items-center gap-1">
                                                        <FaExclamationCircle /> Passwords do not match
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                   </div>
                                   </div>
                                    <button
                                        className="btn btn-success butonRegister margin_mobiles mt-4 mb-3"
                                        onClick={() => {

                                            if(stateTeacher.number.length !== 10){
                                                    toast.error("Please enter 10 digit mobile no");
                                                } else {
                                                    setregistertype("teacher-registration")
                                                    setmobilenumber(stateTeacher.number)
                                                    teacherregisterfunc();
                                                    setSecondsPassed(0)
                                                    setTimerActive(true)
                                                    setisResend(true)
                                                }

                                        }}
                                        disabled={isLoadingreg ? isLoadingreg : checksuccessusernotify && stateTeacher.confirmPwd && Object.keys(selectedSubjectsTeach).length > 0 && stateTeacher.pwd === stateTeacher.confirmPwd ? false : true}
                                    >
                                        {console.log(selectedSubjectsTeach,"testregtecher")}
                                        Register
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
            {/* Teacher Modal */}

            {/* Otp Modal for All */}
            <ModalComponent
                dialogClassName={"modalotsize"}
                show={OtpModal}
                Title={"Enter Your OTP"}
                onClick={() => {
                    setOtpModal(false);
                }}
                Content={
                    <div>
                        <div className="container">
                            <div className="otpContent">
                                6 Digit OTP has been sent to Your Registered Mobile
                                Number <strong>+91 {mobilenumber}</strong>
                            </div>
                            <div className="d-flex justify-content-center align-items-center mt-3 mb-4">
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={<span>&nbsp; &nbsp; &nbsp; &nbsp; </span>}
                                    renderInput={(props) => (
                                        <input {...props} className="otpInput" />
                                    )}
                                    inputType="number"
                                />
                            </div>
                            { isResend && <div className="d-flex justify-content-end align-items-end pt-3 pb-2"
                            style={{
                                color: timerActive === false ? "#000" : "",
                                fontWeight: "600",
                                cursor: timerActive === false ? "pointer" : "default",
                            }}
                            onClick={() => {
                                if(timerActive === false){
                                if(roleName === "Student"){
                                    generateResendOTPfunc("registration","Stu","Student")
                                } else if(roleName === "Parent"){
                                    generateResendOTPfunc("registration","Par","Parent")
                                } else if(roleName === "Teacher"){
                                    generateResendOTPfunc("registration","Tec","Teacher")
                                } 
                                }
                                
                            }}
                            >
                                {timerActive ? `Resend OTP is : ${countdown}` : "Resend OTP"}
                            </div>
                            }
                            <div className="d-flex justify-content-center align-items-center">
                                <button
                                    className="btn btn-success butonRegister w-100"
                                    disabled={otp.length === 6 ? false : true}
                                    onClick={() => {
                                      
                                        // successRegister();
                                        userotpVerfication("registration",registertype);
                                     
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            {/* Otp Modal for All */}

            {/* Login Modal */}
            <ModalComponent
                dialogClassName={"loginSize"}
                show={LoginModal}
                Title={`${roleName} Login`}
                onClick={() => {
                    setLoginModal(false);
                    setroleName("")
                }}
                Content={
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <Input
                                        label={"User Id / Mobile No"}
                                        placeholder={"Enter User Id / Mobile No"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        value={LoginState.user}
                                        onChange={(e) => {
                                            setselecteduserId("")
                                            setUserValidate(false)
                                            setUserValidateList({})
                                            setLoginState({ ...LoginState, user: e.target.value });
                                            console.log(e.target.value,"yyyyyyyyyyy")
                                            console.log(e.target.value.length,"yyyyyyyyyyy")
                                            if(isNumber(e.target.value)){
                                                if(e.target.value.length === 10){
                                                    uservalidateFunc(e.target.value)
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                { UserValidate && UserValidateList.otherUser.map((x,i) => (
                                <span className={"Stu_userData gap-1 cursor"} 
                                style={{
                                    background: selecteduserId === x.userId ? "#004857" : "",
                                    color: selecteduserId === x.userId ? "#ffffff" : ""
                                }}
                                onClick={() => {
                                    setselecteduserId(x.userId)
                                }}
                                >{x.studentId.slice(1)}</span>
                                ))}

                                <div className="col-md-12 mb-3">
                                    <Input
                                        label={"Password"}
                                        placeholder={"Enter Password"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isPassword
                                        value={LoginState.pwd}
                                        onChange={(e) => {
                                            setLoginState({ ...LoginState, pwd: e.target.value });
                                        }}
                                    />
                                </div>
                                <div
                                    className="d-flex justify-content-end forgetpwdfont"
                                    
                                >
                                    <span className="cursor" onClick={() => {
                                        if(LoginState.user === ""){
                                            toast.error("Please enter User Id / Mobile No")
                                        } else if(UserValidate && selecteduserId == ""){
                                            toast.error("Please select any one user Id")
                                        } else {
                                            generateOTPfunc("reset","",roleName);
                                            setSecondsPassed(0)
                                            setTimerActive(true)
                                            setisResend(true)
                                        }
                                    
                                    
                                    }}>
                                        Forgot Password ?
                                    </span>
                                </div>
                                <div className="mb-3">
                                    <button
                                        className="btn btn-success buttonLogin w-100 mt-3"
                                        disabled={!isFormValidLogin()}
                                        onClick={() => {
                                            if(UserValidate && selecteduserId == ""){
                                                toast.error("Please select any one user Id")
                                            } else {
                                                loginuserfunc()
                                            }
                                            
                                        }}
                                    >
                                        Login
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
            {/* Login Modal */}

            {/* Forget Password Modal */}
            <ModalComponent
                dialogClassName={"modalotsize"}
                show={ForgotpwdModal}
                Title={"Forgot Password"}
                onClick={() => {
                    setForgotpwdModal(false);
                }}
                Content={
                    <div>
                        <div className="container">
                            <div className="otpContent">
                                6 Digit OTP has been sent to Your Registered Mobile
                                Number <strong>+91 {loginmobilenumber}</strong>
                                {/* <span className="changecontent cursor">Change</span> */}
                            </div>
                            <div className="d-flex justify-content-center align-items-center mt-3 mb-4">
                                <OtpInput
                                    value={otpPwd}
                                    onChange={setOtpPwd}
                                    numInputs={6}
                                    renderSeparator={<span>&nbsp; &nbsp; &nbsp; &nbsp; </span>}
                                    renderInput={(props) => (
                                        <input {...props} className="otpInput" />
                                    )}
                                    inputType="number"
                                />
                            </div>
                            { isResend && <div className="d-flex justify-content-end align-items-end pt-3 pb-2"
                            style={{
                                color: timerActive === false ? "#000" : "",
                                fontWeight: "600",
                                cursor: timerActive === false ? "pointer" : "default",
                            }}
                            onClick={() => {
                                if(timerActive === false){
                                    generateResendOTPfunc("reset","",roleName)
                                }
                                
                            }}
                            >
                                {timerActive ? `Resend OTP is : ${countdown}` : "Resend OTP"}
                            </div>
                            }
                            <div className="d-flex justify-content-center align-items-center pb-3">
                                <button
                                    className="btn btn-success butonRegister w-100"
                                    //   disabled={!setOtpPwd}
                                    disabled={otpPwd.length === 6 ? false : true}
                                    onClick={() => {
                                        forgototpVerfication()
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            {/* Forget Password Modal */}

            {/* Enter New Password Modal */}
            <ModalComponent
                dialogClassName={"modalotsize"}
                show={pwdModal}
                Title={"Enter Your New Password"}
                onClick={() => {
                    setPwdModal(false);
                }}
                Content={
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <Input
                                        label={"Password"}
                                        placeholder={"Enter Password"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isPassword
                                        value={enterPwd.pwd}
                                        onChange={(e) => {
                                            setEnterPwd({ ...enterPwd, pwd: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className="col-md-12 mb-1">
                                    <Input
                                        label={"Confirm Password"}
                                        placeholder={"Enter Confirm Password"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isPassword
                                        value={enterPwd.confirmPwd}
                                        onChange={(e) => {
                                            setEnterPwd({ ...enterPwd, confirmPwd: e.target.value });
                                        }}
                                    />
                                </div>
                                {enterPwd.confirmPwd && enterPwd.pwd !== enterPwd.confirmPwd && <div className="d-flex justify-content-between align-items-center w-100">
                                    <div className="sugesstionFontred d-flex align-items-center gap-1">
                                    <FaExclamationCircle /> password mismatch
                                    </div>
                                </div>
                                }
                                <div className="mb-3">
                                    <button
                                        className="btn btn-success buttonLogin w-100 mt-3"
                                        disabled={enterPwd.pwd && enterPwd.pwd === enterPwd.confirmPwd ? false : true}
                                        onClick={() => {
                                            pwdResetfunc()
                                        }}
                                        // onClick={pwdResetfunc}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
            {/* Enter New Password Modal */}

            <ModalComponent
                show={routeModal}
                dialogClassName={"ModalSizeMobile"}
                Title={"Routes 2 Roots"}
                onClick={() => {
                    setRouteModal(false);
                }}
                Content={
                    <div className="d-flex justify-content-center align-items-center height_30">
                        <span className="h3">Coming Soon...</span>
                    </div>
                }
            />

            <ModalComponent
                show={ncertModal}
                isNcert={true}
                dialogClassName={"ModalSizeMobile"}
                onClick={() => {
                    setNcertModal(false);
                }}
            />


            {/* Student Add Book Modal */}
            <ModalComponent
                // size={"xl"}
                dialogClassName={"register_modal"}
                show={StudentAddbookModal}
                Title={`Add ${UserProfileData?.standard[0]?.variableName} Standard Katbook's`}
                onClick={() => {
                    setStudentAddbookModal(false);
                    setaddbookOpen(false)
                }}
                className={"Register_insidescroll"}
                Content={
                    <div>
                        <div className="container">
                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"Medium of Instruction"}
                                        Selectplaceholder={"Select Medium of Instruction"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        // options={options[2]}
                                        isDropdown
                                        isMulti
                                        value={state.MediumInstruction}
                                        // onChange={(e) => {
                                        //     setState({ ...state, MediumInstruction: e });
                                        //     getstandard(e);
                                        // }}
                                        onChange={handleaddbookMediumChange}
                                        options={mediumarray}
                                    />
                                </div>
                            </div>

                            {state.Standard.length > 0 && <div className="col-md-6 d-flex flex-row pt-2 gap-5 w-100">
                                <div className="d-flex flex-column w-100 gap-1">
                                    <label className={` Select_label `}>
                                        Subject<code>*</code>
                                    </label>

                                    <div className="selectallsubjectBack">
                                        <div className='d-flex flex-row flex-wrap align-items-center row-gap-2 column-gap-5'>
                                            {subarray.map((x, i) => (
                                                <div
                                                    key={i}
                                                    className={`d-flex gap-2 ${i >= 6 && !showAllSubjects ? "d-none" : ""
                                                        }`}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        disabled={UserProfileData?.subjects?.length > 0 && UserProfileData?.subjects?.filter(aa => aa.parentVaribaleId._id === x.value).length > 0 ? true : false}
                                                        checked={selectedSubjects.some(subject => subject.label === x.label && subject.value === x.value)}
                                                        onChange={() => handleCheckboxChange(x)}
                                                    />
                                                    <label className="subject_style">{x.label}</label>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="d-flex justify-content-end border-bottom pb-1">
                                            {subarray.length > 6 && (
                                                <div
                                                    onClick={handleButtonClick}
                                                    className="cursor subject_style pt-2"
                                                >
                                                    {showAllSubjects ? "View Less  ▲" : "View More  ▼ "}
                                                </div>
                                            )}
                                        </div>
                                        
                                        <hr style={{ border: "2px dashed black" }} />
                                        <div className="d-flex justify-content-between align-items-center pt-1 w-100">
                                            <div className="Select_label d-flex flex-wrap w-75">
                                                Selected Subject :  <br/> {selectedSubjects.map(subject => subject.label).join(', ')}
                                            </div>
                                            {/* <div className="d-flex gap-2 pt-1 button_width">
                                                <div
                                                    type="button"
                                                    className="select_button "
                                                    onClick={handleSelectAll}
                                                >
                                                    Select All
                                                </div>
                                                <div
                                                    type="button"
                                                    className="clear_button"
                                                    onClick={handleClearAll}
                                                >
                                                    Clear All
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            
                            <div className="col-md-12 d-flex flex-column gap-2 justify-content-center align-items-center modal-dropdown-size">
                                <div className="col-md-6 Register_input gap-2 w-100 justify-content-between">
                                    <p className={"note_txtbook"}>Note: As a student, they are allowed to select a maximum of 6 subjects.</p>
                                    <button
                                        className="btn btn-success butonRegister mt-4 mb-3"
                                        onClick={() => {
                                            updateBooks("student")
                                        }}
                                        disabled={UserProfileData?.subjects.length < selectedSubjects.length ? false : true}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                }
            />
            {/* Student Registration Modal */}


            {/* Teacher Add book Modal */}
            <ModalComponent
                dialogClassName={"register_modal"}
                show={TeacherAddbookModal}
                Title={"Add Katbook"}
                className={"Register_insidescroll"}
                onClick={() => {
                    setTeacherAddbookModal(false);
                    setaddbookOpen(false)
                }}
                Content={
                    <div>
                        <div className="container">

                            <div className="row pt-2 input_gap">
                                <div className="col-md-6">
                                    <Input
                                        label={"Medium of Instruction"}
                                        Selectplaceholder={"Select Medium of Instruction"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isDropdown
                                        isMulti
                                        // options={options[2]}
                                        value={stateTeacher.MediumInstruction}
                                        // onChange={(e) => {
                                        //     setStateTeacher({ ...stateTeacher, MediumInstruction: e, Standard: [] });
                                        //     getstandard(e);
                                        // }}
                                        onChange={handleTeaMediumChange}
                                        options={mediumarray}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label={"Standard"}
                                        Selectplaceholder={"Select Standard"}
                                        labelClassName={"Select_label"}
                                        className={"input-size p-2"}
                                        isDropdown
                                        isMulti
                                        value={stateTeacher.Standard}
                                        // onChange={(e) => {
                                        //     setStateTeacher({ ...stateTeacher, Standard: e });
                                        //     getsubjectlist(e, "teacher");
                                        // }}
                                        onChange={handleTeacherstdChangeForAddBook}
                                        options={stdteacherarray}
                                        isDisabled={stateTeacher.MediumInstruction.length > 0 ? false : true}
                                    />
                                </div>
                            </div>
                            { console.log(stateTeacher.MediumInstruction,"MediumInstruction")}

                                {stateTeacher.Standard.length > 0 && Object.keys(subteacherarray).length > 0 && 
                                <div className="col-md-6 d-flex flex-row gap-5 w-100">
                                    <div className="d-flex flex-column w-100 gap-1">
                                        <label className={` Select_label `}>
                                            Subject<code>*</code>
                                        </label>
                                        <div className="position-relative d-flex flex-row"></div>


                                        <div className="selectallsubjectBack">
                                            {Object.keys(subteacherarray).map(className => (
                                                <div key={className}>
                                                    <div className="pb-1">
                                                        <div className="Select_label" > Standard -  {className}</div>
                                                    </div>
                                                    <div className="d-flex flex-row flex-wrap align-items-center row-gap-2 column-gap-4">
                                                        {subteacherarray[className].map((x, i) => (
                                                            <div
                                                                key={i}
                                                                className={`d-flex gap-2 ${i >= 6 && !showAllSubjectsTeach ? "d-none" : ""
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    disabled={selectedSubjects[x._id]}
                                                                    checked={String(selectedSubjectsTeach[x._id]) === "true" ? true : false}
                                                                    onChange={() => handleCheckboxChangeTeach(x._id)}
                                                                />
                                                                <label className="subject_style"> {x.variableName}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                            {/* { console.log(selectedSubjects,"DDDDDDDDDD") } */}
                                            { console.log(selectedSubjectsTeach,"DDDDDDDDDD") }
                                            <div className="d-flex justify-content-end border-bottom pb-1">
                                                {Object.values(subteacherarray).flat().length > 6 && (
                                                    <div
                                                        onClick={handleButtonClickTeach}
                                                        className="cursor subject_style pt-2"
                                                    >
                                                        {showAllSubjectsTeach
                                                            ? "View Less ▲"
                                                            : "View More ▼ "}
                                                    </div>
                                                )}
                                            </div>


                                            <div className="d-flex justify-content-end gap-2 pt-1">
                                                <div
                                                    type="button"
                                                    className="select_button"
                                                    onClick={handleSelectAllTeach}
                                                >
                                                    Select All
                                                </div>
                                                <div
                                                    type="button"
                                                    className="clear_button"
                                                    onClick={handleClearAllTeach}
                                                >
                                                    Clear All
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            

                            <hr style={{ border: "2px dashed black" }} />

                            <div className="col-md-12 d-flex flex-column gap-2 justify-content-center align-items-center modal-dropdown-size">
                                <div className="col-md-6 Register_input gap-2 w-100 justify-content-between">
                                <p className={"note_txtbook"}>Note: As a teacher, they are allowed to select a maximum of 4 standards and a maximum of 8 subjects.</p>
                                    <button
                                        className="btn btn-success butonRegister margin_mobiles mt-4 mb-3"
                                        onClick={() => {
                                            updateBooks("teacher")
                                        }}
                                        disabled={stateTeacher.Standard.length > 0 && Object.keys(selectedSubjectsTeach).length > 0 ? false : true}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
            {/* Teacher Add book Modal */}

        </Fragment>
    );
};

// Default props for activeTab and handleTabClick
Header.defaultProps = {
    activeTab: "Home", // Default active tab is set to 'Home'
    handleTabClick: () => { }, // Default click handler is an empty function
};

export default Header;