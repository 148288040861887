import React, { useState } from "react";
import "./Input.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ReactSelect from "react-select";
const Input = ({
    label,
    className,
    labelClassName,
    placeholder,
    value,
    onChange,
    errorMessage,
    showRightIcon,
    isPassword,
    isDropdown,
    isDate,
    type,
    options,
    isDisabled,
    maxLength,
    key,
    id,
    onClick,
    Selectplaceholder,
    isValid,
    isMulti,
    onKeyDown,
    maxDate,
    minDate
}) => {
    const [showPassword, setShowPassword] = useState(false);
    // console.log(isDisabled);

    return (
        <div className="d-flex flex-column w-100 gap-1">
            <label className={` ${labelClassName} ? ${labelClassName} : Label_Font `}>
                {label}<code>*</code>
            </label>
            {isDropdown ? (
                <ReactSelect
                    value={value === "" ? null : value}
                    onChange={onChange}
                    className={"Dropdown_Back_Color"}
                    options={options}
                    isMulti={isMulti}
                    isDisabled={isDisabled}
                    placeholder={Selectplaceholder}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#237E85',
                            primary: '#237E85',
                        },
                    })}
                />
            ) : (
                <div className="position-relative d-flex flex-row">
                    <input
                        disabled={isDisabled}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        id={id}
                        key={key}
                        onClick={onClick}
                        maxLength={maxLength}
                        max={maxDate}
                        min={minDate}
                        onKeyDown={onKeyDown}
                        autocomplete="off"
                        className={`w-100 Input_Style Input_Back_Color ${className}`}
                        type={
                            isPassword
                                ? showPassword
                                    ? "text"
                                    : "password"
                                : isDate
                                    ? "date"
                                    : type
                                        ? type
                                        : "text"
                        }
                    />

                    {isPassword || showRightIcon ? (
                        <div
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}
                            className="position-absolute cursor-pointer"
                            style={{
                                right: 10,
                                top: "15%",
                            }}
                        >
                            {showRightIcon ? (
                                showRightIcon
                            ) : showPassword ? (
                                <AiOutlineEye size={20} />
                            ) : (
                                <AiOutlineEyeInvisible size={20} />
                            )}
                        </div>
                    ) : null}
                </div>
            )}
            <div className="err-msg-false">{errorMessage}</div>
            {/* <div className={isValid ? "err-msg-true" : "err-msg-false" }>{errorMessage}</div> */}
        </div>
    );
};

Input.defaultProps = {
    value: "",
    id: "",
    key: "",
    maxLength: "",
    onChange: () => { },
    onClick: () => { },
    errorMessage: "",
    label: "",
    placeholder: "",
    showRightIcon: null,
    isPassword: false,
    isDropdown: false,
    options: [],
    isDisabled: false,
    labelClassName: "",
    className: "",
    Selectplaceholder: "",
    maxDate: 0,
    minDate: ""
    // isValid:null
};

export default Input;
