// local IP
// const URL = "http://192.168.2.33:4000/v1";

// public IP
// const URL = "http://49.207.184.116:4000/v1";
// create content

// server ip
const URL = `https://api.pmevidyakatbook.in/v1`;

export async function getmedium() {

  const response = await fetch(`${URL}/variable/medium`,
    {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400 || response.status === 404) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);

  }
}
export const getstandardbyMID = async (body, type) => {

  try {
    let apiUrl = `${URL}/variable/standard`;
    if (type === "teacher") {
      apiUrl = `${URL}/variable/standard?type=groupby`;
    }
    const response = await fetch(apiUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
export const checkuserid = async (body) => {
  console.log(body, "testsce")
  try {
    const response = await fetch(`${URL}/user/userId/check?userId=${body}`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
export const createTeacher = async (body) => {
  try {
    const response = await fetch(`${URL}/user/teacher/add`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
export const createStudent = async (body) => {
  try {
    const response = await fetch(`${URL}/user/student/add`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
export const addStudentBook = async (userId, body) => {
  try {
    const response = await fetch(`${URL}/user/books/add/${userId}`, {
      method: `put`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
export const createParent = async (body) => {
  try {
    const response = await fetch(`${URL}/user/parent/add`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
export const useridsuggestion = async (body) => {
  try {
    const response = await fetch(`${URL}/user/name/suggestions`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
export async function getuseridDetails(body) {

  const response = await fetch(`${URL}/user/get?userId=${body}`,
    {
      method: `get`,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400 || response.status === 404) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);

  }
}
export const userlogin = async (body) => {
  try {
    const response = await fetch(`${URL}/user/login`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
export async function otpverfication(body) {
  let mobile = body.mobilenumber
  let otp = body.otp
  let type = body.type
  let role = body.role
  const response = await fetch(`${URL}/user/otp/validate?mobile=${mobile}&otp=${otp}&type=${type}&role=${role}`,
    {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400 || response.status === 404) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);

  }
}
export async function otpgenerate(body) {

  let mobile = body.mobile
  let type = body.type
  let userid = body.userid
  let role = body.role
  let apiUrl = `${URL}/user/otp/send?mobile=${mobile}&type=${type}&role=${role}`;
  if (type === "reset" && userid !== null) {
    apiUrl = `${URL}/user/otp/send?userId=${userid}&type=${type}&role=${role}`;
  }
  const response = await fetch(apiUrl,
    {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400 || response.status === 404) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);

  }
}

export const forgetpwd = async (body) => {
  try {
    const response = await fetch(`${URL}/user/password/reset`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};


export const getAllKatBookSubjects = async (body) => {
  try {
    const response = await fetch(`${URL}/variable/books/get`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};

export const getUserListFromUsers = async (body) => {
  try {
    const response = await fetch(`${URL}/user/getid`, {
      method: `post`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
};
