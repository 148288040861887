import React, { useEffect, useState } from "react";
import laptop from "../../Assests/Slide 1 Katbook 1.png";
import FooterLogo from "../../Assests/foot.png"
import appstore from "../../Assests/Group.png";
import mobile from "../../Assests/smartphones 1.png";
import playstore from "../../Assests/Group (1).png";
import "./Katbook.css";
import flowerOne from "../../Assests/KbookIntroIconOne.b9ac608049a38e7964e34f2fbd51595c 1.png";
import flowertwo from "../../Assests/two.png";
import flowerthree from "../../Assests/tree.png";
import flowerfour from "../../Assests/fur.png";
import flowerOnes from "../../Assests/ones.png";
import flowertwos from "../../Assests/tws.png";
import flowerthrees from "../../Assests/tress.png";
import flowerfours from "../../Assests/frss.png";
import thirdEmbelem from "../../Assests/thirdEmbelem.png";
import Students from "../../Assests/stud.png";
import Teachers from "../../Assests/twach.png";
import Parents from "../../Assests/paren.png";
import scren1 from "../../Assests/1.png";
import screen2 from "../../Assests/2.png";
import screen3 from "../../Assests/3.png";
import scren4 from "../../Assests/4.png";
import screen5 from "../../Assests/5.png";
import screen6 from "../../Assests/6.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import men from "../../Assests/image 10.png";
import AccordionItem from "./AccordionItem";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "../header/Header";
import Footer from "../footer/Footer";


const KATBOOK = ({ RoutesClick, NcertClick, studentClick, teacherClick, parentClick }) => {
  const [registerModal, setRegisterModal] = useState(false);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  

  const [selectedCategory, setSelectedCategory] = useState("student"); // State to track the selected category

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const [benefit, setBenefit] = useState({
    student: true,
    teacher: false,
    parent: false,
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: false,
    nextArrow: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    AOS.init();
  }, []);

  const [activeTabHeader, setActiveTabHeader] = useState("KATBOOK");
  const [studentregisterModal, setStudentRegisterModal] = useState(false);
  const [ParentregisterModal, setParentRegisterModal] = useState(false);
  const [TeacherregisterModal, setTeacherregisterModal] = useState(false);

  const handleTabClickHeader = (tab) => {
    setActiveTabHeader(tab);
  };

  return (
    <div>
      <Header
        activeTab={activeTabHeader}
        handleTabClick={handleTabClickHeader}
        studentregisterModal={studentregisterModal}
        setStudentRegisterModal={setStudentRegisterModal}
        ParentregisterModal={ParentregisterModal}
        setParentRegisterModal={setParentRegisterModal}
        TeacherregisterModal={TeacherregisterModal}
        setTeacherregisterModal={setTeacherregisterModal}
        registerModal={registerModal}
        setRegisterModal={setRegisterModal}
        RoutesClick={RoutesClick}
        NcertClick={NcertClick}
      />
      <div className="py-5">
        <div className='imgBack'>
          <div className='container py-5 d-flex align-items-center height645'>
            <div className="col-md-12 d-flex justify-content-center align-items-center h-100" >
              <div className="col-md-6">
                <div className='d-flex flex-column gap-3'>
                  <div className="webDis">
                    <img src={laptop} className='width_mob' />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className='d-flex flex-column gap-3'>
                    <div className="mobiDis mb-3 ">
                      <img src={laptop} className='width_mob' />
                    </div>
                  </div>
                  <div className='katbookbannerSbtext'>
                    FUTURE OF ENHANCED LEARNING
                  </div>
                  <div className='katbookbannerHeadtext'>
                    KATBOOK
                  </div>
                  <div className='mb-3 d-flex flex-column gap-2'>
                    <div className="katbookbannersubcontent"> The Revolutionary All-in-One Advanced Digital Textbook.</div>
                  </div>
                  <div className="katbookbannerSbtext">
                    <img src={FooterLogo} style={{ width: "35%" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="katsenondback">
          <div className="sofa_first">
            <div className="d-flex flex-column justify-content-start mx-5 py-5 gap-3">
              <div className="sofaHead">WELCOME TO KATBOOK</div>
              <div className="sofaSubHead">
                Your Gateway to Comprehensive Digital Learning
              </div>
              <div className="sofaContent">
                That combines the power of enhanced content, concept and teaching
                videos with the convenience of anywhere,<br />
                anytime access. Our platform revolutionizes the method of teaching and learning, making it simple and effective
                <br />
                {/* with integrated communication support. */}
                With KATBOOK, students can 
                learn at their own pace, engaging with interactive 
                content. It empowers students <br />
                with personalized learning throughout their educational journey.
              </div>
            </div>
          </div>

          <div className="sofa_second">
            <div className="katsofaMobio">
              <div className="d-flex flex-column justify-content-start gap-5 mb-5">
                <div data-aos="fade-down">
                  <div className="d-flex justify-content-start gap-3 align-items-center">
                    <div>
                      <img src={flowerOne} className="flowerback" />
                    </div>
                    <div className="flowerFont">
                      360-degree additional <br /> information on all <br />{" "}
                      Keywords.
                    </div>
                  </div>
                </div>
                <div data-aos="fade-down">
                  <div className="d-flex justify-content-start gap-3 align-items-center">
                    <div>
                      <img src={flowertwo} className="flowerback" />
                    </div>
                    <div className="flowerFont">
                      Sliced concept and <br />
                      teaching videos <br />
                      embedded within <br />
                      sessions.
                    </div>
                  </div>
                </div>
                {/* <div data-aos="fade-down">
                    <div className="d-flex justify-content-start gap-3 align-items-center">
                      <div>
                        <img src={flowerthree} className="flowerback" />
                      </div>
                      <div className="flowerFont">
                        In-book doubt clearing <br /> and collaboration <br />{" "}
                        mechanism.
                      </div>
                    </div>
                  </div> */}

              </div>

              <div className="d-flex flex-column justify-content-start gap-5 mb-5">
                <div data-aos="fade-down">
                  <div className="d-flex justify-content-start gap-3 align-items-center">
                    <div>
                      <img src={flowerfour} className="flowerback" />
                    </div>
                    <div className="flowerFont">
                      Enhanced features <br /> bridge the gap between <br />{" "}
                      digital and tangible <br /> reading experiences.
                    </div>
                  </div>
                </div>
                <div data-aos="fade-down">
                  <div className="d-flex justify-content-start gap-3 align-items-center">
                    <div>
                      <img src={flowerOnes} className="flowerback" />
                    </div>
                    <div className="flowerFont">
                      Offline, Online, and <br /> hybrid modes of the <br /> book
                      are available.
                    </div>
                  </div>
                </div>
                {/* <div data-aos="fade-down">
                    <div className="d-flex justify-content-start gap-3 align-items-center">
                      <div>
                        <img src={flowertwos} className="flowerback" />
                      </div>
                      <div className="flowerFont">
                        Provision to raise doubts <br /> from all parts of the{" "}
                        <br />
                        textbook/learning <br /> material.
                      </div>
                    </div>
                  </div> */}
                {/* <div data-aos="fade-down">
                    <div className="d-flex justify-content-start gap-3 align-items-center">
                      <div>
                        <img src={flowerthrees} className="flowerback" />
                      </div>
                      <div className="flowerFont">
                        Notification of the <br />
                        clarification sought to all <br /> stakeholders.
                      </div>
                    </div>
                  </div> */}
                {/* <div data-aos="fade-down">
                    <div className="d-flex justify-content-start gap-3 align-items-center">
                      <div>
                        <img src={flowerfours} className="flowerback" />
                      </div>
                      <div className="flowerFont">
                        FAQ from previous <br />
                        doubts raised.
                      </div>
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="katbookthird py-5">
          <div data-aos="fade-down" className="d-flex flex-column justify-content-center align-items-center w-100">
            <div className="thirdpostionHead">UNIQUE FEATURES OF</div>
            <div className="thirdpostionBigHead">KATBOOK</div>
          </div>

          <div className="container text-center">
            <div className="row justify-content-start">
              <div className="col-lg-4 clo-md-12" data-aos="fade-right">
                <div className="d-flex flex-column justify-content-center gap-3 w-100 mb-5">
                  <div className="thirdleftcolHead">Additional Content</div>
                  <div className="thirdleftcolconent">
                    KATBOOK provides 360-degree additional information on all
                    Keywords so that the students are aware of the information
                    and can face any question around them.
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center gap-3 w-100">
                  <div className="thirdleftcolHead">Self-Assessment</div>
                  <div className="thirdleftcolconent">
                    Students can always evaluate their understanding of the lesson that is taught in the class, chapter-wise. Multiple
                    types of evaluations are made available with timer to make
                    the student confident.
                  </div>
                </div>
              </div>
              <div className="col-lg-4 clo-md-12">
                <div data-aos="zoom-in-down">
                  <div className="mt-3">
                    <img src={thirdEmbelem} />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 clo-md-12" data-aos="zoom-in-down">
                <div className="d-flex flex-column justify-content-start align-items-start gap-3 w-100 mb-5">
                  <div className="thirdleftcolHeads">Embedded Videos</div>
                  <div className="thirdleftcolconents">
                    KATBOOK provides embedded sliced concept videos when a new
                    concept is introduced and teaching videos at appropriate
                    places within the session.
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-start align-items-start gap-3 w-100">
                  <div className="thirdleftcolHeads">And many more…</div>
                  <div className="thirdleftcolconents">
                    Session-wise mapping of the KATBOOK, Notes, Highlighting,
                    Bookmarks, Referencing, Dictionary, Audio reader, Translate, Solved Exercises, and many more features.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="container text-center py-3 mb-5">
            <div className="row align-items-start h-100 d-flex justify-content-center align-items-center">
              <div data-aos="fade-right" className="col-md-6">
                <div className="d-flex flex-column justify-content-start align-items-start gap-4 mt-5 text-start">
                  <div className="tabletHeading">KATBOOK Mobile App</div>
                  <div className="about_COntent">
                    KATBOOK can be accessed on desktops/ laptops/ tablet computers
                    and mobile phones of Android and iOS operating systems. The mobile application gives the convenience of studying from anywhere at any time.
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-start align-items-start gap-4 mt-5 text-start">
                  <div className="featureheading">
                    Features of KATBOOK Mobile Application.
                  </div>

                  <div className="d-flex justify-content-start gap-2">
                    <div>
                      <ul className="d-flex flex-column gap-3">
                        <li className="listfont">
                          Additional content for all Keywords{" "}
                        </li>
                        <li className="listfont">
                          {" "}
                          Concept and Teaching Videos
                        </li>
                        <li className="listfont">
                          {" "}
                          Notes, Dictionary, Search.
                        </li>
                        <li className="listfont"> Bookmark, Highlight</li>
                      </ul>
                    </div>
                    <div>
                      <ul className="d-flex flex-column gap-3">
                        {/* <li className="listfont">In-book Clarification.</li> */}
                        <li className="listfont"> Reference with Hyperlink</li>
                        <li className="listfont"> Audio reader</li>
                        <li className="listfont">
                          {" "}
                          AI-powered questions and answers
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="d-flex justify-content-start gap-3">
                    <a href="https://apps.apple.com/us/app/pm-evidya-katbook/id6474897126" target="_blank">
                      <div>
                        <img src={appstore} />
                      </div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.ueducate.pmevidya&pcampaignid=web_share" target="_blank">
                      <div>
                        <img src={playstore} />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6" data-aos="fade-top">
                <div className="mt-5">
                  <img src={mobile} className="imgs" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pinkBack">
          <div className="container text-center py-5">
            <div data-aos="fade-down">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="pinkhead">KATBOOK - A Store of Benefits</div>
                <div className="pinkSubHead">For every one</div>
              </div>
            </div>
            <div data-aos="fade-down">
              <div className="d-flex justify-content-center align-items-center gap-4 py-5">
                <div className="row">
                  {/* Button for Students */}
                  <div className="col-lg-4 mb-3">
                    <div
                      className={
                        selectedCategory === "student"
                          ? "btnforBenfits cursor"
                          : "btnforBenfitsd cursor"
                      }
                      onClick={() => {
                        handleCategoryClick("student");
                        setBenefit({
                          student: true,
                          teacher: false,
                          parent: false,
                        });
                      }}
                    >
                      <div className="d-flex flex-column justify-content-start align-items-start">
                        <div
                          className={
                            selectedCategory === "student" ? "for" : "ford"
                          }
                        >
                          For
                        </div>
                        <div
                          className={
                            selectedCategory === "student"
                              ? "beneGen"
                              : "beneGend"
                          }
                        >
                          Students
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Button for Teachers */}
                  <div className="col-lg-4 mb-3">
                    <div
                      className={
                        selectedCategory === "teacher"
                          ? "btnforBenfits cursor"
                          : "btnforBenfitsd cursor"
                      }
                      onClick={() => {
                        handleCategoryClick("teacher");
                        setBenefit({
                          student: false,
                          teacher: true,
                          parent: false,
                        });
                      }}
                    >
                      <div className="d-flex flex-column justify-content-start align-items-start">
                        <div
                          className={
                            selectedCategory === "teacher" ? "for" : "ford"
                          }
                        >
                          For
                        </div>
                        <div
                          className={
                            selectedCategory === "teacher"
                              ? "beneGen"
                              : "beneGend"
                          }
                        >
                          Teachers
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Button for Parents */}
                  <div className="col-lg-4 mb-3">
                    <div
                      className={
                        selectedCategory === "parent"
                          ? "btnforBenfits cursor"
                          : "btnforBenfitsd cursor"
                      }
                      onClick={() => {
                        handleCategoryClick("parent");
                        setBenefit({
                          student: false,
                          teacher: false,
                          parent: true,
                        });
                      }}
                    >
                      <div className="d-flex flex-column justify-content-start align-items-start">
                        <div
                          className={
                            selectedCategory === "parent" ? "for" : "ford"
                          }
                        >
                          For
                        </div>
                        <div
                          className={
                            selectedCategory === "parent" ? "beneGen" : "beneGend"
                          }
                        >
                          Parents
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {benefit.student && (
              <div className="row justify-content-start">
                <div data-aos="fade-right" className="col-lg-8">
                  <div className="d-flex flex-column justify-content-start align-items-start gap-3">
                    <div className="headfor">Benefits for Students</div>
                    <div className="subheadfor">
                      Shaping Futures: Unleash your potential with us!
                    </div>
                    <div className="contentfor">
                      KATBOOK empowers students with 360° immersive learning
                      through additional content <br />
                      on identified keywords, concept videos, teaching videos,
                      and unlocking complete
                      <br />
                      knowledge with simplified explanations. Its anytime accessibility fosters a classroom-like
                      <br />
                      experience anywhere. With KATBOOK, students accelerate
                      their progress, advance <br />
                      ahead, and unlock fine knowledge.
                      {/* Students can access the
                        Online classNamees or Hybrid <br />
                        classNamees from the Dynamic timetable directly in a single
                        Click. */}
                    </div>
                    <div className="benefitbtnOnReg cursor" onClick={() => { setRegisterModal(true) }}> Register</div>
                  </div>
                </div>
                <div className="col-lg-3" data-aos="fade-top">
                  <div>
                    <img src={Students} className="benImgback" />
                  </div>
                </div>
              </div>
            )}

            {benefit.teacher && (
              <div className="row justify-content-start">
                <div className="col-lg-8">
                  <div data-aos="fade-right">
                    <div className="d-flex flex-column justify-content-start align-items-start gap-3">
                      <div className="headfor">Benefits for Teachers</div>
                      <div className="subheadfor">
                        Empowering Teachers to Inspire, Innovate,and Ignite Minds
                      </div>
                      <div className="contentfor">
                        KATBOOK revolutionizes teaching by empowering
                        educatorswith a plethora of benefits. <br />
                        From session-wise lesson mapping,and enhanced content
                        creation to real-time <br />
                        progress tracking ofevery student, efficient doubt
                        clarification available throughout <br />
                        theyear, and gain more time for preparation, teachers
                        experiencenewfound efficiency <br />
                        and effectiveness in their teaching. Teacherscan start
                        Online and Hybrid classNamees for the <br />
                        students from theDynamic Timetable itself. With KATBOOK,
                        teachers can focuson what <br />
                        truly matters—inspiring and guiding students to reachtheir
                        full potential.
                      </div>
                      <div className="benefitbtnOnReg cursor" onClick={teacherClick}> Register</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div data-aos="fade-left">
                    <div>
                      <img src={Teachers} className="benImgbackGirl" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {benefit.parent && (
              <div className="row justify-content-start">
                <div className="col-lg-8">
                  <div data-aos="fade-right">
                    <div className="d-flex flex-column justify-content-start align-items-start gap-3">
                      <div className="headfor">Benefits for Parents</div>
                      <div className="subheadfor">
                        Real-Time Progress; EnsuringParental Assurance
                      </div>
                      <div className="contentfor">
                        KATBOOK offers parents peace of mind by providing
                        theirchildren with a <br />
                        comprehensive and effective learning platform.With
                        enhanced content, 24/7 doubt- <br />
                        clearing mechanisms, andreal-time progress reports,
                        KATBOOK ensures their child' <br />
                        academicprogress and boosts confidence. The collaborative
                        learning <br />
                        environmentallows parents to add value to the in-book
                        discussions. It <br />
                        fostersindependent learning to students, giving parents a
                        cost-effectivesolution for <br />
                        their child's education.
                      </div>
                      <div className="benefitbtnOnReg cursor" onClick={parentClick}> Register</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div data-aos="fade-left">
                    <div>
                      <img src={Parents} className="benImgback" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <div className="container py-5">
            <div className="d-flex justify-content-center align-items-center w-100 mb-3">
              <div className="screnshotHead">APP SCREENSHOTS</div>
            </div>

            <Slider {...settings}>
              
              <div>
                <img src={screen2} height={300} />
              </div>
              <div>
                <img src={screen5} height={300} />
              </div>
              <div>
                <img src={screen3} height={300} />
              </div>
              <div>
                <img src={scren1} height={300} />
              </div>
              <div>
                <img src={scren4} height={300} />
              </div>
              
              <div>
                <img src={screen6} height={300} />
              </div>
            </Slider>
          </div>
        </div>

        <div className="backKids">
          <div className="container py-5 mb-4">
            <div className="row justify-content-between">
              <div className="col-md-7">
                <div className="d-flex flex-column justify-content-start align-items-start">
                  <div data-aos="fade-down">
                    <div className="kidsHead">
                      Elevate Your Learning with <br /> KATBOOK'S AI-POWERED
                      Educational Solutions
                    </div>
                  </div>

                  <div className="accordianMobi mb-3">
                    <div className="d-flex flex-column justify-content-center gap-4 mt-3">
                      <div data-aos="fade-down">
                        <AccordionItem
                          title="Universal Coverage"
                          content="Comprehensive Content of  NCERT Books."
                        />
                      </div>
                      <div data-aos="fade-down">
                        <AccordionItem
                          title="Enhanced Insights"
                          content="Quality Additional Content for Every Subject, Topic, and Keyword."
                        />
                      </div>
                      {/* <div data-aos="fade-down">
                          <AccordionItem
                            title="Comprehensive Session Split"
                            content="Each session is divided into 3-4 parts, enabling students to access concept explanations, teaching videos, and receive doubt clarification. The Session can be Optimized for Maximum Engagement."
                          />
                        </div> */}
                      <div data-aos="fade-down">
                        <AccordionItem
                          title="Simplified Learning"
                          content="Concept Videos Demystifying Complex Concepts."
                        />
                      </div>
                      <div data-aos="fade-down">
                        <AccordionItem
                          title="Expert-Led Learning"
                          content="On-Demand Teaching Videos for Flexible and Effective Learning."
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column justify-content-center gap-4 mt-3">
                      {/* <div data-aos="fade-down">
                          <AccordionItem
                            title="Previous year question papers"
                            content="Previous Year Question Papers with Answer Keys Included."
                          />
                        </div> */}
                      <div data-aos="fade-down">
                        <AccordionItem
                          title="Choice of Platform"
                          content="Access Anywhere with Cloud Solutions."
                        />
                      </div>
                      <div data-aos="fade-down">
                        <AccordionItem
                          title="Inclusive Access for All"
                          content="Empowering Students, Teachers, and Parents."
                        />
                      </div>
                      {/* <div data-aos="fade-down">
                          <AccordionItem
                            title="Seamless Teaching & Learning"
                            content="Empowering Institutions and Students with World-className on-campus, off-campus, Hybrid Teaching."
                          />
                        </div> */}
                      <div data-aos="fade-down">
                        <AccordionItem
                          title="Delivery Channels"
                          content="Students can get the KATBOOK from the KATBOOK Store for their individual use."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <img src={men} className="imgs" width={100} />
              </div>
            </div>
          </div>
        </div>
      </div >
      <Footer 
      setActiveTab={setActiveTabHeader}
      RoutesClick={RoutesClick}
      NcertClick={NcertClick}
       />
    </div>
  );
};

export default KATBOOK;
