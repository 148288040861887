import React, { Fragment, useState } from 'react'
import "./Footer.css"
import FooterLogo from "../../Assests/foot.png"
import appstore from "../../Assests/Group.png"
import playstore from "../../Assests/Group (1).png"
import nav from "../../Assests/nav.png"
import mess from "../../Assests/mess.png"
import call from "../../Assests/call.png"
import { useNavigate } from "react-router-dom";
import ModalComponent from '../modalComponent/ModalComponent'

const Footer = ({ setActiveTab, RoutesClick, NcertClick }) => {

    const [routeModal, setRouteModal] = useState(false);
    const [ncertModal, setNcertModal] = useState(false);

    const navigate = useNavigate();

    const homeClick = () => {
        if (window.location.pathname === '/') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            navigate('/');
        }
    };
    const katbookClick = () => {
        // Navigate to a different route
        navigate('/katbook');
    };
    return (

        <Fragment>
            <div className='container mb-5 pt-2'>
                <div className='row align-items-center'>
                    <div className="col-sm-12 col-lg-5">
                        <div className='d-flex flex-column justify-content-start gap-4'>
                            <div className='text_center_mobile'>
                                <img src={FooterLogo} style={{ width: "50%" }} />
                            </div>

                            <div className='footLogoFont'>
                                To know more about the company,please visit us at <a href='https://ueducate.in/' className='footspecialfont textDecvor' target='_blank'>ueducate.in.</a>
                            </div>


                            <div className='d-flex justify-content-start gap-3 mb-4'>
                                <a href="https://apps.apple.com/us/app/pm-evidya-katbook/id6474897126" target="_blank">
                                    <div>
                                        <img src={appstore} />
                                    </div>
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.ueducate.pmevidya&pcampaignid=web_share" target="_blank">
                                    <div>
                                        <img src={playstore} />
                                    </div>
                                </a>
                            </div>

                            <div className='copyrightsfont webDis'>Copyright © 2023 katbookstore.com</div>
                        </div>
                    </div>


                    <div className="col-sm-5 col-lg-3">
                        <div className='d-flex flex-column justify-content-start gap-3 '>
                            <div className='footer_Quick'>Quick Links</div>
                            <div className='footer_Quick_content mx-4 ' onClick={() => { setActiveTab('Home'); homeClick() }}> <span className='cursor textDecvor'>Home</span></div>
                            <div className='footer_Quick_content mx-4 ' onClick={() => { setActiveTab('KATBOOK'); katbookClick() }}><span className='cursor textDecvor'>Katbook</span></div>
                            <div className='footer_Quick_content mx-4' onClick={() => NcertClick()}><span className='cursor'>NCERT</span></div>
                            <div className='footer_Quick_content mx-4' onClick={() => RoutesClick()}><span className='cursor'>Routes 2 Roots</span></div>
                            <div className='footer_Quick_content mx-4' onClick={() => window.open(`https://diksha.gov.in/`, "_blank")}><span className='cursor'>DIKSHA</span></div>
                        </div>
                    </div>

                    <div className="col-sm-5 col-lg-4">
                        <div className='d-flex flex-column justify-content-start gap-3 foottop'>
                            <div className='footer_Quick'>Contact Us</div>
                            <div className='d-flex gap-2'>
                                <div>
                                    <img src={nav} />
                                </div>
                                <div className='footer_Quick_content'>1st Floor, “CHETTINAD CHAMBERS”,No 39, 5th <br /> street, Radha Krishnan Salai, Mylapore,<br /> Chennai- 600004, Tamil Nadu, India.</div>
                            </div>
                            <div className='d-flex gap-2 align-items-center'>
                                <div>
                                    <img src={mess} />
                                </div>
                                <div className='footer_Quick_content'>support@katbookstore.com</div>
                            </div>
                            <div className='d-flex gap-2 align-items-center'>
                                <div>
                                    <img src={call} />
                                </div>
                                <div className='footer_Quick_content'>+91 98848 00819</div>
                            </div>

                            {/* <div className='copyrightsfont mobiDis d-flex justify-content-center mt-3 '>Copyright © 2023 katbookstore.com</div> */}
                        </div>
                    </div>

                </div>
            </div >

            <ModalComponent
                show={routeModal}
                dialogClassName={"ModalSizeMobile"}
                Title={"Routes 2 Roots"}
                onClick={() => {
                    setRouteModal(false);
                }}
                Content={
                    <div className="d-flex justify-content-center align-items-center height_30">
                        <span className="h3">Coming Soon...</span>
                    </div>
                }
            />

            <ModalComponent
                show={ncertModal}
                isNcert={true}
                dialogClassName={"ModalSizeMobile"}
                onClick={() => {
                    setNcertModal(false);
                }}
            />

        </Fragment>
    )
}

export default Footer
